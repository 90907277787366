'use client';

import cn from 'classnames';
import { usePathname } from 'next/navigation';
import React, { useEffect, useRef } from 'react';
import slugify from 'react-slugify';

import ProductCard from '@/app/_components/product-card/ProductCard';
import { ProductM2M } from '@/app/_types/components/product-category';
import { FilesMeta } from '@/app/_types/file';

import Styles from './ProductListing.module.scss';

type ProductListingProps = {
  heading?: string | null;
  subHeading?: string | null;
  products?: ProductM2M[];
  filesMeta?: FilesMeta;
};

const ProductListing = (props: ProductListingProps) => {
  const { heading, subHeading, products, filesMeta = {} } = props;

  const pathName = usePathname();

  const categoryRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const offset = 120;
    const hash = window.location.hash;
    const activeSection = hash.replace('#', '');

    if (activeSection === slugify(heading) && categoryRef.current) {
      const scrollPosition = categoryRef.current.offsetTop - offset;

      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }, [pathName]);

  return (
    <div>
      <div
        className={cn('pageSection', Styles.categoryWrapper)}
        id={slugify(heading)}
        ref={categoryRef}
      >
        <div className={Styles.productWrapper}>
          <div className="container">
            <div className={Styles.sectionHeadingContainer}>
              <div className={Styles.sectionLeftContent}>
                {heading && (
                  <div className="headingElement">
                    {subHeading && <h3 className="subTitle">{subHeading}</h3>}
                    {heading && <h2 className="titleXl">{heading}</h2>}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="container">
            {products && (
              <div
                className={cn(
                  Styles.productCards,
                  products.length < 3 && Styles.productCardsLess,
                )}
              >
                {products.map((productM2M, productIndex) => {
                  const product = productM2M.products_id;

                  return (
                    <ProductCard
                      key={productIndex}
                      {...product}
                      url={product.learn_more_cta_url}
                      filesMeta={filesMeta}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListing;
