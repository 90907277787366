import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';
import { formatDate } from '@/app/_utils/date-format';

import Styles from './CtaBanner.module.scss';

type Props = {
  filesMeta?: FilesMeta;
  title?: string | null;
  date?: string | null;
  author?: string | null;
  bannerImage?: string | null;
  readingTime?: string | null;
  categoryTitle?: string | null;
  categoryHref?: string;
};

const CtaBanner = (props: Props) => {
  const {
    title,
    date,
    author,
    bannerImage,
    readingTime,
    categoryTitle,
    categoryHref,
    filesMeta = {},
  } = props;

  return (
    <div className={Styles.ctaBannerWrapper}>
      <div className="container">
        <div className={Styles.ctaBannerContent}>
          <div className="headingElement">
            <h1 className="titleXXXl strong">{title && title}</h1>
          </div>
          <ul className={Styles.metaData}>
            {date && <li>{formatDate(date)}</li>}
            {author && <li>{author}</li>}
            {readingTime && <li>{readingTime}</li>}
            {categoryTitle && (
              <li>
                {categoryHref ? (
                  <Link href={categoryHref}>{categoryTitle}</Link>
                ) : (
                  <span>{categoryTitle}</span>
                )}
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className={Styles.bannerImageContainer}>
        {bannerImage && (
          <Image
            src={getDirectusAssetURI(bannerImage) ?? ''}
            alt={getAltProperty(filesMeta, bannerImage, 'CTA Image')}
            width={1440}
            height={754}
            className={Styles.bannerImage}
          />
        )}
      </div>
    </div>
  );
};

export default CtaBanner;
