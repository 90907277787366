import cn from 'classnames';
import React from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { HeaderComponent } from '@/app/_types/components/header-component';

import styles from './LandingPage.module.scss';

type LandingPageProps = {
  data: HeaderComponent;
  onStart: () => void;
};

const LandingPage = ({ data, onStart }: LandingPageProps) => {
  return (
    <div className="findSolutionPageSection">
      <div className={styles.findSolutionWrapper}>
        <div className={styles.headingWrapper}>
          <div className={cn('headingElement', styles.ctaBannerTitle)}>
            {data.static_title && <h1 className="titleXXl light">{data.static_title}</h1>}
          </div>
          <div className={cn('headingElement', 'text-primary strong', styles.ctaBannerTitle)}>
            {data.dynamic_titles && data.dynamic_titles.length > 0 && (
              <h2 className="titleXXl">{data.dynamic_titles[0].option}</h2>
            )}
          </div>
        </div>
        <div className={styles.rightWrapper}>
          {data?.paragraph && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: data?.paragraph,
              }}
            ></div>
          )}
          {data.cta_button && (
            <ButtonCustom onClick={onStart} btnText={data.cta_button.text} />
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
