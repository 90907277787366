'use client';
import React, { useMemo } from 'react';

import BlogArticleCards from '@/app/_components/blog-article-cards/BlogArticleCards';
import FAQs from '@/app/_components/faqs/FAQs';
import { Blog } from '@/app/_types/blog';
import { DocumentGroupM2M } from '@/app/_types/components/document-group';
import { OfferingCardM2M } from '@/app/_types/components/offering-card';
import { FilesMeta } from '@/app/_types/file';
import { ResourcesVariation1 } from '@/app/_types/resources-page';

import CtaBanner from './CtaBanner';
import FormsAndDocuments from './FormsAndDocuments';
import GuidelinesSection from './GuidelinesSection';
import MediaSection from './MediaSection';
import ServiceSection from './ServiceSection';

type Props = ResourcesVariation1 & {
  filesMeta: FilesMeta;
  blogsList?: Blog[];
  formAndDocumentList: DocumentGroupM2M[];
};

const ResourcesPageVariation1 = (props: Props) => {
  const {
    header,
    documents_title,
    documents_pre_text,
    formAndDocumentList,
    document_cta_text,
    document_cta_url,
    faq_title,
    faq_pre_text,
    faqs,
    insurance_claims_pre_text,
    insurance_claims_title,
    claims_and_assesments,
    service_providers_and_partners_pre_text,
    service_providers_and_partners_title,
    service_providers_and_partners,
    blog_title,
    blog_pre_text,
    blog_cta_text,
    blog_cta_url,
    blog_detail_navigation,
    media_title,
    media_pre_text,
    media_cta_text,
    media_cta_url,
    media_cards,
    filesMeta,
    blogsList,
  } = props;

  const blogDetailPermalink = blog_detail_navigation?.permalink;
  let blogBaseUrl = '';

  if (blogDetailPermalink) {
    blogBaseUrl = blogDetailPermalink.replaceAll('*', '');
  }

  const mapCards = (cards: OfferingCardM2M[]) => {
    return cards?.map((cardM2M) => {
      const { cta_url, ...rest } = cardM2M.offering_cards_id;

      return {
        ...rest,
        url: cta_url,
      };
    });
  };

  const updatedServiceCardsList = useMemo(() => {
    return (
      service_providers_and_partners && mapCards(service_providers_and_partners)
    );
  }, [service_providers_and_partners]);

  return (
    <>
      {header && <CtaBanner {...header} filesMeta={filesMeta} />}
      {formAndDocumentList &&
        formAndDocumentList?.length > 0 && (
          <FormsAndDocuments
            documentList={formAndDocumentList}
            documentTitle={documents_title}
            preText={documents_pre_text}
            ctaText={document_cta_text}
            ctaUrl={document_cta_url}
          />
        )}
      {claims_and_assesments && claims_and_assesments?.length > 0 && (
        <GuidelinesSection
          claimsList={claims_and_assesments}
          preText={insurance_claims_pre_text}
          title={insurance_claims_title}
          filesMeta={filesMeta}
        />
      )}
      {updatedServiceCardsList && updatedServiceCardsList?.length > 0 && (
        <ServiceSection
          providerList={updatedServiceCardsList}
          title={service_providers_and_partners_title}
          preText={service_providers_and_partners_pre_text}
        />
      )}
      {blogsList && blogsList?.length > 0 && (
        <BlogArticleCards
          blogList={blogsList}
          title={blog_title}
          preText={blog_pre_text}
          ctaText={blog_cta_text}
          ctaUrl={blog_cta_url}
          blogBaseUrl={blogBaseUrl}
          filesMeta={filesMeta}
        />
      )}
      {media_cards && media_cards?.length > 0 && (
        <MediaSection
          mediaList={media_cards}
          title={media_title}
          preText={media_pre_text}
          ctaText={media_cta_text}
          ctaUrl={media_cta_url}
          filesMeta={filesMeta}
        />
      )}
      {faqs && faqs?.length > 0 && (
        <FAQs faqsList={faqs} preText={faq_pre_text} faqTitle={faq_title} />
      )}
    </>
  );
};

export default ResourcesPageVariation1;
