import React, { useEffect, useRef, useState } from 'react';

import { StepRepeater } from '@/app/_types/guideline-page';

import Styles from './Steps.module.scss';

type StepsProps = {
  title?: string | null;
  preText?: string | null;
  steps: StepRepeater[];
};

const Steps = (props: StepsProps) => {
  const { title, preText, steps } = props;

  const stepsContainerRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const checkScrollable = () => {
      const container = stepsContainerRef.current;

      if (container) {
        setIsScrollable(container.scrollWidth > container.clientWidth);
      }
    };

    checkScrollable();
    window.addEventListener('resize', checkScrollable);
    return () => {
      window.removeEventListener('resize', checkScrollable);
    };
  }, [steps]);

  // Group steps into rows of three
  const groupedSteps = [];

  for (let i = 0; i < steps.length; i += 3) {
    groupedSteps.push(steps.slice(i, i + 3));
  }

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.stepsWrapper}>
          <div className={Styles.sectionHeadingContainer}>
            <div className={Styles.sectionLeftContent}>
              <div className="headingElement">
                {preText && <h3 className="subTitle">{preText}</h3>}
                {title && <h2 className="titleXl">{title}</h2>}
              </div>
            </div>
          </div>
          {steps && (
            <div
              className={Styles.stepsContainer}
              ref={stepsContainerRef}
              style={{ cursor: isScrollable ? 'grab' : 'default' }}
            >
              {groupedSteps.map((group, groupIndex) => (
                <div key={groupIndex} className={Styles.stepsRow}>
                  {group.map((step, stepIndex) => (
                    <div key={stepIndex} className={Styles.stepCard}>
                      <div className={Styles.stepCardCounter}>
                        <div className={Styles.stepCardNumber}>
                          {groupIndex * 3 + stepIndex + 1}
                        </div>
                      </div>
                      <div className={Styles.stepsCardBody}>
                        <div className="headingElement">
                          <h3 className="titleMd">{step.title}</h3>
                        </div>
                        <div className={Styles.stepCardDescription}>
                          {step.description}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Steps;
