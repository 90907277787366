'use client';
import { useEffect, useState } from 'react';

import { Product } from '@/app/_types/components/product';
import {
  FindMySolution,
  FindMySolutionNode,
} from '@/app/_types/find-my-solution';

import CardStep from './card-step';
import LandingPage from './landing-page/LandingPage';
import MyOffers from './my-offers/MyOffers';
import ProductOptionSelection from './product-option-selection/ProductOptionSelection';

const MultiStepFindSolution = ({ data }: { data: FindMySolution }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [currentStepData, setCurrentStepData] = useState<any>({});
  const [complete, setComplete] = useState<boolean>(false);
  const [finalProducts, setFinalProducts] = useState<Product[]>([]);
  const [mainCategory, setMainCategory] = useState('');
  const [_stepHistory, setStepHistory] = useState<FindMySolutionNode[]>([]);

  // Leaving this here in case they want it back
  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // }, [currentStepIndex]);

  const handleClose = () => {
    setCurrentStepData(data.find_my_solution_root_node);
    setCurrentStepIndex(0);
    if (finalProducts.length > 0) {
      setFinalProducts([]);
      setComplete(false);
    }
  };

  const handleNext = () => {
    setStepHistory((prevHistory) => [...prevHistory, currentStepData]);
    setCurrentStepIndex((i) => i + 1);
  };

  const handleBack = () => {
    setStepHistory((prevHistory) => {
      const updatedHistory = [...prevHistory];
      const previousStepData = updatedHistory.pop();

      setCurrentStepData(previousStepData || data.find_my_solution_root_node);
      return updatedHistory;
    });
    setCurrentStepIndex((i) => Math.max(0, i - 1));
  };

  const scrollTopSuccess = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsFormSubmitted(true);
  };

  const handleFinalStep = (nodes: any) => {
    const productIds = Array.isArray(nodes)
      ? nodes.flatMap(
          (node: any) =>
            node.products?.map((product: any) => product.products_id) || [],
        )
      : [];

    if (
      !Array.isArray(nodes) &&
      nodes.is_product_external &&
      nodes.external_link
    ) {
      window.open(nodes.external_link, '_blank');
      handleBack();
      return;
    }
    setFinalProducts(productIds);
    setComplete(true);
  };

  useEffect(() => {
    if (currentStepIndex === 0) {
      setCurrentStepData(data.find_my_solution_root_node);
    }
  }, [currentStepIndex]);

  const renderSteps = () => {
    if (currentStepIndex !== 0 && !currentStepData.is_checkbox_group) {
      return (
        <CardStep
          data={currentStepData}
          onNext={handleNext}
          onBack={handleBack}
          setCurrentStepData={setCurrentStepData}
          handleFinalStep={handleFinalStep}
          header={data.header}
          currentStepIndex={currentStepIndex}
          mainCategory={mainCategory}
          setMainCategory={setMainCategory}
          handleClose={handleClose}
        />
      );
    } else if (currentStepData.is_checkbox_group) {
      return (
        <ProductOptionSelection
          data={currentStepData}
          onBack={handleBack}
          handleFinalStep={handleFinalStep}
          mainCategory={mainCategory}
          header={data.header}
          handleClose={handleClose}
        />
      );
    } else {
      return (
        <LandingPage key="landing" onStart={handleNext} data={data.header} />
      );
    }
  };

  return (
    <div className="mySolutionContainer">
      {complete ? (
        <MyOffers
          scrollTopSuccess={scrollTopSuccess}
          isFormSubmitted={isFormSubmitted}
          finalProducts={finalProducts}
          resultTitle={data.result_title || ''}
          resultDescription={data.result_description || ''}
          mainCategory={mainCategory}
          header={data.header}
          form={data.form}
          handleClose={handleClose}
        />
      ) : (
        <>{renderSteps()}</>
      )}
    </div>
  );
};

export default MultiStepFindSolution;
