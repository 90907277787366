type Props = {
  type: 'next' | 'prev'
};

const PaginationArrow = (props: Props) => {
  const { type } = props;

  if (type === 'prev') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          fill="currentColor"
          d="M12.7 7.2H5.3l3.3-3.3c.3-.3.3-.8 0-1.1-.3-.3-.8-.3-1.1 0L2.8 7.4c-.1.1-.2.2-.2.3-.1.2-.1.4 0 .6 0 .1.1.2.1.2l4.7 4.7c.2.2.4.2.6.2.2 0 .4-.1.6-.2.3-.3.3-.8 0-1.1L5.3 8.8h7.4c.4 0 .8-.4.8-.8s-.4-.8-.8-.8z"
        ></path>
      </svg>
    );
  }
  if (type === 'next') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          fill="currentColor"
          d="M13.5 8c0-.1 0-.2-.1-.3 0-.1-.1-.2-.2-.2L8.6 2.8c-.3-.3-.8-.3-1.1 0-.3.3-.3.8 0 1.1l3.3 3.3H3.3c-.4 0-.8.4-.8.8s.4.8.8.8h7.4l-3.3 3.3c-.3.3-.3.8 0 1.1.2.2.4.2.6.2.2 0 .4-.1.6-.2l4.7-4.7c.1-.1.1-.2.2-.2-.1-.1 0-.2 0-.3z"
        ></path>
      </svg>
    );
  }
  return null;
};

export default PaginationArrow;