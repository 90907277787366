import React from 'react';

import { ManagerRepeater } from '@/app/_types/components/partner';

import Styles from './GetInContact.module.scss';

type GetInTouchProps = {
  managerList: ManagerRepeater[];
  preText: string | null;
  title: string | null;
};

const GetInContact = (props: GetInTouchProps) => {
  const { managerList, preText, title } = props;
  const renderContact = (title: string, contact: string) => {
    switch (title) {
      case 'full_name':
        return (
          <span className={Styles.getInContactListContact}>{contact}</span>
        );
      case 'number':
        return (
          <a href={`tel:${contact}`} className={Styles.getInContactListContact}>
            {contact}
          </a>
        );
      case 'website':
        return (
          <a
            href={contact}
            className={Styles.getInContactListContact}
            target="_blank"
          >
            {contact}
          </a>
        );
      case 'email':
        return (
          <a
            href={`mailto:${contact}`}
            className={Styles.getInContactListContact}
          >
            {contact}
          </a>
        );
      default:
        return (
          <span className={Styles.getInContactListContact}>{contact}</span>
        );
    }
  };

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.getInContactWrapper}>
          <div className="headingElement">
            {preText && <h3 className="subTitle">{preText}</h3>}
            {title && <h2 className="titleXl">{title}</h2>}
          </div>

          <div className={Styles.getInContactBox}>
            <ul className={Styles.getInContactList}>
              {managerList?.map((item, index) => (
                <React.Fragment key={index}>
                  {Object.entries(item)
                    .filter(([_, value]) => value && value.trim() !== '')
                    .map(([key, value]) => (
                      <li key={key}>
                        <div className={Styles.getInContactListItem}>
                          <h4
                            className={Styles.getInContactListTitle}
                            dangerouslySetInnerHTML={{
                              __html: key === 'number' ? 'Telephone' : key,
                            }}
                          ></h4>
                          {renderContact(key.toLowerCase(), value as string)}
                        </div>
                      </li>
                    ))}
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInContact;
