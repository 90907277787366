import { Category } from "@/app/_types/categories";
import { BlogM2M, MediaResourcesM2M } from "@/app/_types/media-page";

export const calculateResourceCounts = (filteredResources: MediaResourcesM2M[]) => {
  let counts: Record<string, number> = {};

  counts = filteredResources.reduce((acc, currentValue) => {
    const key = currentValue.media_resources_id.category?.value;

    // tally up everything under _, if they have a category or not
    if (acc['_']) {
      acc['_'] = acc['_'] + 1;
    } else {
      acc['_'] = 1;
    }

    if (!key) {
      return acc;
    }

    // if we have a category, add up the totals for that category too
    if (acc[key]) {
      acc[key] = acc[key] + 1;
    } else {
      acc[key] = 1;
    }
    return acc
  }, counts);

  return counts;
};

export const findCategoryByValue = (categories: Category[], value: string | null | undefined) => {
  if (!value) {
    return null;
  }
  return categories.find((category) => {
    return category.value === value;
  }) || null;
};

export const filterResourcesByCategories = (resources: MediaResourcesM2M[], categories: string[]) => {
  return resources.filter((item) =>
    item.media_resources_id.category &&
    categories.includes(item.media_resources_id.category.value)
  );
};

export const filterBlogsByCategories = (blogs: BlogM2M[] | null | undefined, categories: string[]) => {
  return blogs?.filter((item) =>
    item.blog_id?.category &&
    categories.includes(item.blog_id?.category.value)
  );
};
