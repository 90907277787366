'use client';
import Image from 'next/image';
import React from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import WarningImage from '@/app/_images/warning.png';

import Styles from './NoResultsFound.module.scss';

const NoResultsFound = () => {
  return (
    <div className={Styles.noResultsWrapper}>
      <Image src={WarningImage} alt="Warning" height={160} width={160} />
      <div className={Styles.noResultsContent}>
        <div className="headingElement">
          <h2 className="titleLg">Oh No!</h2>
        </div>
        <p>
          There&apos;s nothing here that matches your search, please try edit your
          search or filters
        </p>
      </div>
      <ButtonCustom btnText="Back to Home" href="/" />
    </div>
  );
};

export default NoResultsFound;
