import { Form } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import FormInput from '@/app/_components/form/FormInput';
import { Button } from '@/app/_types/components/button';
import { FormSchema } from '@/app/_types/components/form-copy';
import { LocationCard } from '@/app/_types/components/location-card';
import { Vendor } from '@/app/_types/components/vendor';
import { VendorBranch } from '@/app/_types/components/vendor-branch';

import ResultListing from './ResultListing';
import Styles from './SearchCompanyForm.module.scss';

type FormProps = {
  pre_text?: string;
  title?: string;
  description?: string;
  backToStartUrl?: Button;
  nextButton?: Button;
  vendor?: Vendor;
  onBack?: () => void;
  form_inputs?: FormSchema;
  vendorBranch?: VendorBranch;
  companyResultPreText?: string;
  companyResultTitle?: string;
  companyResultParagraph?: string;
  headOffice?: LocationCard | null;
  noVendorIcon: string;
  noVendorTitle: string;
  noVendorParagraph: string;
  backToStartButton: Button;
};

const SearchCompanyForm = (props: FormProps) => {
  const {
    pre_text,
    title,
    description,
    form_inputs,
    backToStartUrl,
    vendor,
    onBack,
    nextButton,
    vendorBranch,
    companyResultPreText,
    companyResultTitle,
    companyResultParagraph,
    headOffice,
    noVendorIcon,
    noVendorTitle,
    noVendorParagraph,
    backToStartButton,
  } = props;

  const [form] = Form.useForm();
  const [showResult, setShowResult] = useState(false);
  const [filteredVendorBranch, setFilteredVendorBranch] =
    useState<VendorBranch>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleFormChange = () => {
    const hasValidInput = form.getFieldsValue(true);
    const isAnyFieldFilled = Object.values(hasValidInput).some(
      (value) => value,
    );

    setIsButtonDisabled(!isAnyFieldFilled);
  };

  const onSubmitForm = (values: any) => {
    const filteredBranches =
      vendorBranch?.filter((branch) => {
        return Object.values(values).some((value) => {
          if (value) {
            return Object.values(branch).some((branchValue) =>
              branchValue
                ?.toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase()),
            );
          }
          return false;
        });
      }) ?? [];

    setFilteredVendorBranch(filteredBranches);
    setShowResult(true);
  };

  useEffect(() => {
    handleFormChange();
  }, []);

  const stepWizardRef = useRef<HTMLDivElement | null>(null);

  const scrollToStepWizard = () => {
    const offset = 120;

    if (stepWizardRef.current) {
      const scrollPosition = stepWizardRef.current.offsetTop - offset;

      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  };

  const handleContinue = () => {
    form.submit();
    scrollToStepWizard();
  };

  const filteredVendorBranchWithoutOfferings = filteredVendorBranch.map(
    ({ branch_offerings: _constants, ...rest }) => rest,
  );

  const handleBack = () => {
    if (showResult) {
      return null;
    } else if (onBack) {
      onBack();
    }
  };

  return (
    <div className={Styles.wizardCard}>
      {!showResult ? (
        <>
          <div className={Styles.sectionHeadingContainer}>
            <div className="headingElement">
              {pre_text && <h3 className="subTitle">{pre_text}</h3>}
              {title && <h2 className="titleXl">{title}</h2>}
            </div>
            {description && (
              <div
                className={Styles.sectionDescription}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </div>
          <Form
            form={form}
            onFinish={onSubmitForm}
            onFieldsChange={handleFormChange}
            preserve={true}
          >
            <div className={Styles.formInputs}>
              {Array.isArray(form_inputs) &&
                form_inputs.map((field, index) => (
                  <div key={index} className={Styles.searchCompanyFormField}>
                    <FormInput key={index} form={form} input={field} />
                  </div>
                ))}
            </div>
          </Form>

          <div className={Styles.stepWizard}>
            <div className={Styles.wizardFooter}>
              <div className={Styles.wizardBtnNavigation}>
                {backToStartUrl && (
                  <button onClick={handleBack} className={Styles.backBtn}>
                    {backToStartUrl?.text}
                  </button>
                )}
                {nextButton && (
                  <ButtonCustom
                    onClick={handleContinue}
                    htmlType="submit"
                    disabled={isButtonDisabled}
                  >
                    {nextButton.text}
                  </ButtonCustom>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {vendor && (
            <ResultListing
              vendorBranch={filteredVendorBranchWithoutOfferings}
              backToStartUrl={backToStartUrl}
              preText={companyResultPreText}
              title={companyResultTitle}
              paragraph={companyResultParagraph}
              headOffice={headOffice}
              noVendorIcon={noVendorIcon}
              noVendorTitle={noVendorTitle}
              noVendorParagraph={noVendorParagraph}
              backToStartButton={backToStartButton}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SearchCompanyForm;
