import cn from 'classnames';
import Link from 'next/link';
import React from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { DocumentGroupM2M } from '@/app/_types/components/document-group';

import Styles from './FormsAndDocuments.module.scss';

type FormsAndDocumentsTypes = {
  documentList?: DocumentGroupM2M[];
  documentTitle?: string | null;
  preText?: string | null;
  ctaText?: string | null;
  ctaUrl?: string | null;
};

const FormsAndDocuments = (props: FormsAndDocumentsTypes) => {
  const { documentTitle, preText, documentList, ctaText, ctaUrl } = props;

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.formsAndDocumentsWrapper}>
          <div className={Styles.sectionHeadingContainer}>
            <div className={Styles.sectionLeftContent}>
              <div className="headingElement">
                {preText && <h3 className="subTitle">{preText}</h3>}
                {documentTitle && <h2 className="titleXl">{documentTitle}</h2>}
              </div>
            </div>
          </div>
          <div className={Styles.documentsContainer}>
            {documentList && (
              <>
                <div className={Styles.documentsList}>
                  {documentList.map(({ document_group_id }, index) => (
                    <Link
                      href={document_group_id?.navigation?.permalink ?? ''}
                      key={index}
                      className={Styles.documentItem}
                    >
                      <div className="headingElement">
                        <h3 className="titleMd">
                          {document_group_id?.title}
                        </h3>
                      </div>
                      {document_group_id?.description && (
                        <div
                          className={cn(Styles.documentParagraph, 'editorDescription')}
                          dangerouslySetInnerHTML={{ __html: document_group_id?.description }}
                        ></div>
                      )}
                    </Link>
                  ))}
                </div>
                {ctaText && ctaUrl && (
                  <ButtonCustom href={ctaUrl} btnText={ctaText} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormsAndDocuments;
