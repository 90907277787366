'use client';

import Image from 'next/image';
import React from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { Button } from '@/app/_types/components/button';
import { getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './NoResultsFound.module.scss';

type Props = {
  noVendorIcon: string;
  noVendorTitle: string;
  noVendorParagraph: string;
  backToStartButton: Button;
};

const NoResultsFound = (props: Props) => {
  return (
    <div className={Styles.noResultsWrapper}>
      <Image
        src={getDirectusAssetURI(props?.noVendorIcon ?? '') || ''}
        alt="Warning"
        height={160}
        width={160}
      />
      <div className={Styles.noResultsContent}>
        <div className="headingElement">
          <h2 className="titleLg">{props?.noVendorTitle}</h2>
        </div>
        <p>{props?.noVendorParagraph}</p>
      </div>
      <ButtonCustom
        btnText={props?.backToStartButton?.text}
        href={props?.backToStartButton?.url ?? ''}
      />
    </div>
  );
};

export default NoResultsFound;
