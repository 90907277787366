import cn from 'classnames';
import Image from 'next/image';
import React from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { CTABanner } from '@/app/_types/components/cta-banner';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI, getDirectusDownloadAssetURI } from '@/app/_utils/assets';

import Styles from './ArticleContent.module.scss';

type ArticleContentTypes = {
  content?: string | null;
  description?: string;
  brochure?: {
    title?: string;
    paragraph?: string;
    image?: string;
    url?: string;
  };
  ctaBanner?: CTABanner | null;
  documents?: Array<
    | {
        title?: string;
        url?: string;
      }
    | undefined
  >;
  filesMeta?: FilesMeta;
};

const ArticleContent = (props: ArticleContentTypes) => {
  const { content, ctaBanner, documents, filesMeta = {}, description } = props;

  const callToActionHref = ctaBanner?.call_to_action?.pdf
    ? getDirectusDownloadAssetURI(ctaBanner.call_to_action.pdf)
    : ctaBanner?.call_to_action?.url || null;

  return (
    <div className={Styles.articleContentWrapper}>
      {content && (
        <div
          className={cn(Styles.articleContentContainer, 'editorDescription')}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}

      {description && (
        <div
          className={cn(Styles.articleContentContainer, 'editorDescription')}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {ctaBanner && (
        <div className="container">
          <div className={Styles.articleContentBrochureCard}>
            <div className={Styles.articleContentBrochureImageContainer}>
              <Image
                src={
                  getDirectusAssetURI(ctaBanner.image ? ctaBanner.image : '') ??
                  ''
                }
                alt={getAltProperty(
                  filesMeta,
                  ctaBanner.image ? ctaBanner.image : 'CTA Image',
                  'CTA Image',
                )}
                height={334}
                width={236}
                className={Styles.cardImage}
              />
            </div>
            <div className={Styles.articleContentBrochureContentContainer}>
              <div className={Styles.articleContentBrochureContent}>
                {ctaBanner.title && (
                  <div className="headingElement">
                    <h3 className="titleLg">{ctaBanner.title}</h3>
                  </div>
                )}
                {ctaBanner.paragraph && (
                  <div
                    className={cn(Styles.description, 'editorDescription')}
                    dangerouslySetInnerHTML={{ __html: ctaBanner.paragraph }}
                  ></div>
                )}
              </div>
              {(ctaBanner?.call_to_action?.url ||
                ctaBanner?.call_to_action?.pdf) && (
                <ButtonCustom
                  btnText={ctaBanner.call_to_action.text}
                  className="btnPrimaryAlt"
                  extraClass={Styles.ctaCardBtn}
                  href={callToActionHref ? callToActionHref : ''}
                  newTab={ctaBanner.call_to_action?.new_tab}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {documents && (
        <div className={Styles.documentsLists}>
          {documents.map((doc, docIndex) => {
            if (!doc) {
              return;
            }
            return (
              <div key={docIndex} className={Styles.documentItemBox}>
                <p className={Styles.documentItemTitle}>{doc.title}</p>
                <div className={Styles.documentItemDownloadSection}>
                  <a
                    href={doc.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={Styles.documentItemDownloadLink}
                    download={doc.title}
                  >
                    Download
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ArticleContent;
