'use client';

import { Form } from 'antd';
import React, { useState } from 'react';

import FloatSelect from '@/app/_components/float-select/FloatSelect';
import { VendorCategory } from '@/app/_types/components/vendor-category';

import Styles from './ServicesSelector.module.scss';

type ServiceSelectorProps = {
  vendorOffering?: VendorCategory;
  onSelectedOptionsChange: (selectedOptions: {
    [key: string]: string | string[];
  }) => void;
};

const ServicesSelector = ({
  vendorOffering,
  onSelectedOptionsChange,
}: ServiceSelectorProps) => {
  const [selectValue, setSelectValue] = useState<{
    [key: string]: string | string[];
  }>({});

  const handleOptionChange = (categoryType: string, value: unknown) => {
    let validValue: string | string[] = [];

    if (typeof value === 'string' || Array.isArray(value)) {
      validValue = value;
    }

    const updatedSelectValue = {
      ...selectValue,
      [categoryType]: validValue,
    };

    setSelectValue(updatedSelectValue);
    onSelectedOptionsChange(updatedSelectValue);
  };

  const processVendorOffering = (vendorOffering: VendorCategory) => {
    return vendorOffering.map((vendor) => {
      const withChildren = vendor?.offerings.filter(
        (offering) => offering.children && offering.children.length > 0,
      );
      const withoutChildren = vendor.offerings.filter(
        (offering) => !offering.children || offering.children.length === 0,
      );

      const offerWithNoChildren = withoutChildren.map((offering) => ({
        value: offering.id.toString(),
        label: offering.name ?? '',
      }));

      return {
        vendor,
        withChildren,
        offerWithNoChildren,
      };
    });
  };

  const processedOfferings = vendorOffering
    ? processVendorOffering(vendorOffering)
    : [];

  return (
    <div className={Styles.servicesSelectorWrapper}>
      <Form className={Styles.servicesForm} size="large" layout="vertical">
        <div className={Styles.servicesFormField}>
          {processedOfferings.map(
            ({ vendor, withChildren, offerWithNoChildren }, vendorIndex) => (
              <div key={`vendor-${vendorIndex}`} className={Styles.vendorBlock}>
                <div className={Styles.dropdownGrid}>
                  {offerWithNoChildren.length > 0 && (
                    <Form.Item
                      name={`${vendor.category_type}`}
                      className="customInput"
                      hasFeedback
                      key={`${vendor.category_type}`}
                    >
                      <FloatSelect
                        label={`${vendor.category_type} `}
                        name={vendor.category_type ?? ''}
                        mode="multiple"
                        allowClear
                        options={offerWithNoChildren}
                        onChange={(value) =>
                          handleOptionChange(vendor.category_type ?? '', value)
                        }
                        value={selectValue[vendor.category_type ?? ''] || []}
                      />
                    </Form.Item>
                  )}

                  {withChildren.map((offering, offeringIndex) => {
                    const options =
                      offering.children?.map((child) => ({
                        value: child.id.toString(),
                        label: child.name ?? '',
                      })) || [];

                    return (
                      <Form.Item
                        name={`offering-${offering.id}`}
                        className="customInput"
                        hasFeedback
                        key={`offering-${offeringIndex}`}
                      >
                        <FloatSelect
                          label={offering.name ?? ''}
                          name={offering.name ?? ''}
                          mode="multiple"
                          allowClear
                          options={options}
                          onChange={(value) =>
                            handleOptionChange(offering.name ?? '', value)
                          }
                          value={selectValue[offering.name ?? ''] || []}
                        />
                      </Form.Item>
                    );
                  })}
                </div>
              </div>
            ),
          )}
        </div>
      </Form>
    </div>
  );
};

export default ServicesSelector;
