'use client';

import React, { useMemo } from 'react';

import ImageMarquee from '@/app/_components/image-marquee/ImageMarquee';
import { CareersImagesM2M } from '@/app/_types/careers-page';
import { FilesMeta } from '@/app/_types/file';

import Styles from './ImageList.module.scss';

type ImageListProps = {
  images: CareersImagesM2M[];
  filesMeta: FilesMeta;
};

const ImageList = ({ images, filesMeta }: ImageListProps) => {
  const updatedImages = useMemo(
    () =>
      images
        .map((item) => item?.career_images_id)
        .filter((item) => item?.image),
    [images],
  );

  return (
    <div className={Styles.imageList}>
      <div className="pageSection">
        <ImageMarquee slider={updatedImages} filesMeta={filesMeta} />
      </div>
    </div>
  );
};

export default ImageList;
