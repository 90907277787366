'use client';
import React from 'react';

import Styles from './HumanRiskElement.module.scss';

type Props = {
  title?: string | null;
  paragraph?: string | null;
};

const HumanRiskElement = (props: Props) => {
  const { title, paragraph } = props;

  return (
    <div className={Styles.humanRiskElementWrapper}>
      <div className={Styles.humanRiskElementContent}>
        <div className={Styles.humanRiskElementHeading}>
          <div className="headingElement full-width">
            {title && <h3 className="titleMd">{title}</h3>}
          </div>
        </div>

        {paragraph && (
          <div
            className={Styles.humanRiskElementQRSection}
            dangerouslySetInnerHTML={{
              __html: paragraph,
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default HumanRiskElement;
