'use client';

import { Divider } from 'antd';
import cn from 'classnames';
import React from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

import ButtonCustom from '@/app/_components/button/ButtonCustom';

import styles from './PersonalizedOffering.module.scss';

type PropsType = {
  preText?: string | null;
  title?: string | null;
  paragraph?: string | null;
  ctaText?: string | null;
  ctaUrl?: string | null;
  clientText?: string | null;
  clientNumber?: number | null;
  payoutText?: string | null;
  payoutNumber?: number | null;
  happyClientText?: string | null;
  happyClientNumber?: number | null;
};

const PersonalizedOffering = (props: PropsType) => {
  const {
    preText,
    title,
    paragraph,
    ctaText,
    ctaUrl,
    clientText,
    clientNumber,
    payoutText,
    payoutNumber,
    happyClientText,
    happyClientNumber,
  } = props;

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const hasCountItems =
    (clientNumber && clientText) ||
    (payoutNumber && payoutText) ||
    (happyClientNumber && happyClientText);

  return (
    <div className="pageSection" ref={ref}>
      <div className={styles.personalizedOfferingWrapper}>
        <div className="container">
          <div className={styles.personalizedOffering}>
            {hasCountItems && (
              <>
                <div className={cn('headingElement', styles.countWrapper)}>
                  {clientNumber && clientText && (
                    <div className={styles.countItem}>
                      <h2 className="titleXXXl">
                        {inView && <CountUp end={clientNumber} duration={3} />}
                      </h2>
                      <p className="light">{clientText}</p>
                    </div>
                  )}
                  {payoutText && payoutNumber && (
                    <div className={styles.countItem}>
                      <h2 className="titleXXXl">
                        {inView && (
                          <CountUp end={payoutNumber} duration={3} suffix="%" />
                        )}
                      </h2>
                      <p className="light">{payoutText}</p>
                    </div>
                  )}
                  {happyClientText && happyClientNumber && (
                    <div className={styles.countItem}>
                      <h2 className="titleXXXl">
                        {inView && (
                          <CountUp
                            end={happyClientNumber}
                            duration={3}
                            prefix="+"
                            separator=""
                          />
                        )}
                      </h2>
                      <p className="light">{happyClientText}</p>
                    </div>
                  )}
                </div>
                <Divider className={styles.quickLinkDivider} />
              </>
            )}
            <div className={cn('headingElement', styles.contentWrapper)}>
              <div className={styles.heading}>
                {preText && <p className="medium">{preText}</p>}
                {title && <h3 className="titleLg">{title}</h3>}
              </div>
              <div className={styles.paragraph}>
                {paragraph && (
                  <div
                    className="normal"
                    dangerouslySetInnerHTML={{
                      __html: paragraph,
                    }}
                  ></div>
                )}
              </div>
              {ctaText && (
                <ButtonCustom
                  btnText={ctaText}
                  href={ctaUrl ?? ''}
                  className="btnPrimaryAlt"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalizedOffering;
