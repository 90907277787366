import cn from 'classnames';
import React from 'react';

import ProductCard from '@/app/_components/product-card/ProductCard';
import { CTABanner as CTABannerType } from '@/app/_types/components/cta-banner';
import { FilesMeta } from '@/app/_types/file';

import Styles from './CtaBanner.module.scss';

type UpdatedInfoCardsType = {
  id: number;
  title?: string | null;
  paragraph?: string | null;
  icon?: string | null;
  url?: string | null;
};

type CtaBannerProps = {
  banner?: CTABannerType | null;
  infoCards?: UpdatedInfoCardsType[] | null;
  filesMeta?: FilesMeta;
};

const CtaBanner = ({ banner, infoCards, filesMeta }: CtaBannerProps) => {
  return (
    <div className={Styles.ctaBannerWrapper}>
      {banner && (
        <div className="container">
          <div className={Styles.ctaBannerContent}>
            <div className="headingElement">
              {(banner?.title || banner?.title_2) && (
                <h1
                  className={cn(
                    'titleXXl text-primary strong',
                    Styles.ctaBannerTitle,
                  )}
                >
                  {banner?.title_2 ? (
                    <>
                      <span className="light">{banner?.title}</span>{' '}
                      {banner?.title_2}
                    </>
                  ) : (
                    banner?.title
                  )}
                </h1>
              )}
            </div>
            {banner?.paragraph && (
              <div
                className={Styles.description}
                dangerouslySetInnerHTML={{
                  __html: banner?.paragraph,
                }}
              ></div>
            )}
          </div>
        </div>
      )}
      <div className="container">
        {infoCards && infoCards.length > 0 && (
          <div className={Styles.productCards}>
            {infoCards.map((card, index) => (
              <ProductCard key={index} {...card} filesMeta={filesMeta} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CtaBanner;
