'use client';
import cn from 'classnames';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import { HeaderComponent } from '@/app/_types/components/header-component';
import { FilesMeta } from '@/app/_types/file';
import { getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './CtaBanner.module.scss';

type Props = HeaderComponent & {
  filesMeta?: FilesMeta;
};

const CtaBanner = (props: Props) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;

      if (scroll >= 350) {
        setScrollPosition(scroll - 350);
      } else {
        setScrollPosition(0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const titles = props?.dynamic_titles || [];

    if (titles.length > 0) {
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % titles.length);
      }, 2000);

      return () => clearInterval(intervalId);
    }
  }, [props?.dynamic_titles]);

  const zoomStyle = {
    transform: `scale(${1 + scrollPosition / 4000})`,
  };

  return (
    <div className={Styles.ctaBannerWrapper}>
      <div className="container">
        <div className={Styles.ctaBannerContent}>
          <div className="headingElement">
            {props?.static_title && (
              <h1 className="titleXXl light">{props?.static_title}</h1>
            )}
            <div className={cn('headingElement', Styles.offeringText)}>
              {props?.dynamic_titles &&
                props?.dynamic_titles.map((text, index) => (
                  <div
                    key={index}
                    className={cn(
                      Styles.offeringTextItem,
                      index === currentIndex && Styles.active, // only active item is visible
                    )}
                  >
                    <h2 className="titleXXl">{text.option}</h2>
                  </div>
                ))}
            </div>
          </div>
          {props?.paragraph && (
            <div
              className={cn(Styles.description, 'editorDescription')}
              dangerouslySetInnerHTML={{ __html: props?.paragraph }}
            ></div>
          )}
        </div>
      </div>
      {props.image && (
        <div className={Styles.bannerImageContainer}>
          <div className={Styles.bannerImageWrapper} style={zoomStyle}>
            <Image
              src={getDirectusAssetURI(props.image) || ''}
              alt="banner image"
              fill
              className={Styles.bannerImage}
              priority
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CtaBanner;
