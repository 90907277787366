'use client';
import React, { useMemo } from 'react';

import CampaignSlider from '@/app/_components/campaign-slider/CampaignSlider';
import HelpCard from '@/app/_components/help-card/HelpCard';
import OurProducts from '@/app/_components/our-products/OurProduct';
import { FilesMeta } from '@/app/_types/file';
import { HomeVariation3 } from '@/app/_types/home';

import CtaBanner from './CtaBanner';

type HomeVariation3Props = HomeVariation3 & {
  filesMeta: FilesMeta;
  copy?: any;
};

const HomePageVariation3 = (props: HomeVariation3Props) => {
  const {
    call_to_action,
    campaigns,
    header_component,
    products,
    filesMeta,

  } = props;

  const updatedProducts = useMemo(() => {
    return (
      products &&
      products?.cards?.map(({ card_id }) => {
        const { category, ...rest } = card_id;

        return {
          ...rest,
          url: category?.navigation?.permalink || '',
        };
      })
    );
  }, [products]);

  return (
    <>
      {header_component &&
        <CtaBanner {...header_component} filesMeta={filesMeta} />
      }
      {products && updatedProducts &&
        <OurProducts
          products={updatedProducts}
          title={products.title}
          preText={products.pre_text}
          description={products.paragraph}
          filesMeta={filesMeta}
        />
      }
      {call_to_action &&
        <HelpCard {...call_to_action} filesMeta={filesMeta} />
      }
      {campaigns && campaigns.length > 0 &&
        <CampaignSlider
          slides={campaigns}
          fullWidth={true}
          filesMeta={filesMeta}
        />
      }
    </>
  );
};

export default HomePageVariation3;
