'use client';
import { Form, message } from 'antd';
import cn from 'classnames';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import React, { useEffect, useMemo, useState } from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import CloudflareTurnstile from '@/app/_components/cloudflare-turnstile/CloudflareTurnstile';
import FormInput from '@/app/_components/form/FormInput';
import { Form as FormType } from '@/app/_types/components/form';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';
import { saveFormData } from '@/app/actions';

import Styles from './CallbackForm.module.scss';

type CallbackFormProps = {
  formDefinition: FormType;
  filesMeta: FilesMeta;
};

const CallbackForm = ({ formDefinition, filesMeta }: CallbackFormProps) => {
  const [form] = Form.useForm();
  const {
    pre_text,
    title,
    submission_key,
    form_inputs,
    submitted_title,
    submitted_paragraph,
    submitted_button,
    submit_text,
    submitted_image,
  } = formDefinition;

  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [isTurnstileLoading, setIsTurnstileLoading] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);

  const initialValues = useMemo(() => {
    const values = form_inputs?.reduce((acc, curr) => {
      const itemName = curr.item.name?.toString();

      if (!itemName) {
        return { ...acc };
      }

      return { ...acc, [itemName]: '' };
    }, {});

    form.setFieldsValue(values);

    return values;
  }, [form_inputs]);

  const [isFormValid, setIsFormValid] = useState(false);

  const values = Form.useWatch([], form);
  const [messageApi, contextHolder] = message.useMessage();
  const pathname = usePathname();
  const saveData = saveFormData.bind(
    null,
    pathname,
    submission_key,
    formDefinition.id,
  );

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsFormValid(true))
      .catch(() => setIsFormValid(false));
  }, [form, values]);

  const getTurnstileToken = (token: string) => {
    setIsTurnstileLoading(false);
    setTurnstileToken(token);
  };

  const handleSubmit = async (data: any): Promise<boolean> => {
    if (!turnstileToken && process.env.NEXT_PUBLIC_TURNSTILE_SITEKEY) {
      messageApi.open({
        type: 'error',
        content: 'Please complete the verification.',
        duration: 5,
      });
      return false;
    }

    const response = await saveData({ ...data, turnstileToken });

    if (!response.success) {
      messageApi.open({
        type: 'error',
        content: response.error,
        duration: 10,
      });
      return false;
    } else {
      form.resetFields();
      setTurnstileToken(null);
      return true;
    }
  };

  return (
    <div className={Styles.callbackFormWrapper}>
      <div className="container">
        {contextHolder}
        <div className={Styles.callbackFormContainer}>
          <div className={Styles.formSection}>
            <div className={Styles.formHeaderWraper}>
              <div className="headingElement">
                {pre_text && <h3 className="subTitle">{pre_text}</h3>}
                {title && (
                  <h2
                    className="titleXl"
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></h2>
                )}
              </div>
            </div>
            {!isFormSubmitted ? (
              <Form
                className={Styles.callbackForm}
                size="large"
                onFinish={(data) => {
                  handleSubmit(data).then((success) =>
                    setIsFormSubmitted(success),
                  );
                }}
                form={form}
                initialValues={initialValues}
              >
                <div className={Styles.callbackFormField}>
                  {form_inputs.map((field) => {
                    return (
                      <FormInput key={field.id} form={form} input={field} />
                    );
                  })}
                </div>
                <CloudflareTurnstile
                  onSuccess={getTurnstileToken}
                  onError={() => setIsTurnstileLoading(false)}
                  onLoad={() => setIsTurnstileLoading(true)}
                />
                <div>
                  <ButtonCustom
                    htmlType="submit"
                    disabled={
                      !form.isFieldsTouched() ||
                      !isFormValid ||
                      isTurnstileLoading
                    }
                  >
                    {submit_text && submit_text}
                  </ButtonCustom>
                </div>
              </Form>
            ) : (
              <div className={Styles.submitContainer}>
                <div className={cn('headingElement', Styles.formSubmit)}>
                  <div className={Styles.submitContent}>
                    <div className={Styles.submitLogo}>
                      {submitted_image && (
                        <Image
                          src={getDirectusAssetURI(submitted_image) || ''}
                          alt={getAltProperty(
                            filesMeta,
                            submitted_image ?? '',
                            'Banner',
                          )}
                          height={159}
                          width={159}
                        />
                      )}
                    </div>
                    <h4 className={cn('titleLg', Styles.submitHeading)}>
                      {submitted_title && submitted_title}
                    </h4>
                    {submitted_paragraph && (
                      <div
                        className={Styles.submitMessage}
                        dangerouslySetInnerHTML={{
                          __html: submitted_paragraph,
                        }}
                      />
                    )}
                  </div>
                </div>
                {submitted_button && (
                  <ButtonCustom
                    className={Styles.homeButton}
                    btnText={submitted_button && submitted_button.text}
                    href={submitted_button && submitted_button.url}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallbackForm;
