'use client';

import React, { useMemo } from 'react';

import CampaignSlider from '@/app/_components/campaign-slider/CampaignSlider';
import FAQs from '@/app/_components/faqs/FAQs';
import HelpCard from '@/app/_components/help-card/HelpCard';
import OurProducts from '@/app/_components/our-products/OurProduct';
import { SingleLevelProductCategory } from '@/app/_types/components/product-category';
import { FilesMeta } from '@/app/_types/file';
import {
  ProductCategoryM2M,
  ProductOverview,
} from '@/app/_types/product-overview';

import CtaBanner from './CtaBanner';
import PersonalizedOffering from './PersonalizedOffering';

type InsuranceProps = ProductOverview & {
  filesMeta: FilesMeta;
};

const InsurancePage = (props: InsuranceProps) => {
  const {
    header,
    personalised_offering_title,
    personalised_offering_pre_text,
    personalised_offering_paragraph,
    cta_text,
    cta_url,
    category_section_pre_text,
    category_section_title,
    category_section_paragraph,
    categories,
    contact_us_banner,
    faq_pre_text,
    faq_title,
    faqs,
    filesMeta,
    new_clients_text,
    new_clients_number,
    payout_rate_text,
    payout_rate_number,
    happy_clients_text,
    happy_clients_number,
    cta_banners,
  } = props;

  const categoryDescriptions = useMemo(() => {
    return (
      categories &&
      categories
        .filter((categoryM2M) => {
          if (typeof categoryM2M === 'number') {
            return false;
          }
          if (typeof categoryM2M.product_category_id === 'number') {
            return false;
          }
          return true;
        })
        .map((categoryM2M) => {
          categoryM2M = categoryM2M as ProductCategoryM2M;
          const category =
            categoryM2M.product_category_id as SingleLevelProductCategory;

          return {
            ...category,
            url: category.navigation?.permalink,
            paragraph: category.description,
          };
        })
    );
  }, [categories]);

  return (
    <>
      {header && <CtaBanner {...header} filesMeta={filesMeta} />}
      <PersonalizedOffering
        preText={personalised_offering_pre_text}
        title={personalised_offering_title}
        paragraph={personalised_offering_paragraph}
        ctaText={cta_text}
        ctaUrl={cta_url}
        clientText={new_clients_text}
        clientNumber={new_clients_number}
        payoutText={payout_rate_text}
        payoutNumber={payout_rate_number}
        happyClientText={happy_clients_text}
        happyClientNumber={happy_clients_number}
      />
      {categoryDescriptions?.length && (
        <OurProducts
          title={category_section_title}
          preText={category_section_pre_text}
          description={category_section_paragraph}
          products={categoryDescriptions}
          filesMeta={filesMeta}
        />
      )}
      {cta_banners && cta_banners.length > 0 && (
        <CampaignSlider
          slides={cta_banners}
          fullWidth={true}
          filesMeta={filesMeta}
        />
      )}
      {contact_us_banner && (
        <HelpCard {...contact_us_banner} filesMeta={filesMeta} />
      )}
      {faqs && faqs?.length > 0 && (
        <FAQs
          faqsList={faqs}
          preText={faq_pre_text ?? ''}
          faqTitle={faq_title ?? ''}
        />
      )}
    </>
  );
};

export default InsurancePage;
