'use client';
import React, { useMemo } from 'react';

import OurProducts from '@/app/_components/our-products/OurProduct';
import { FilesMeta } from '@/app/_types/file';
import { GetInTouch } from '@/app/_types/get-in-touch';

import CallbackForm from './CallbackForm';

type Props = GetInTouch & {
  filesMeta: FilesMeta;
};

const LeadGeneration = (props: Props) => {
  const { categories, category_title, category_pre_text, form, filesMeta } =
    props;

  const categoryDescriptions = useMemo(() => {
    return categories && categories.map((categoryM2M) => {
      const category = categoryM2M.product_category_id;

      return {
        ...category,
        url: category.navigation?.permalink,
        paragraph: category.description,
      };
    });
  }, [categories]);

  return (
    <>
      <CallbackForm formDefinition={form} filesMeta={filesMeta} />
      {categoryDescriptions?.length && (
        <OurProducts
          products={categoryDescriptions}
          title={category_title}
          preText={category_pre_text}
        />
      )}
    </>
  );
};

export default LeadGeneration;
