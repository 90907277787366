'use client';
import { Divider, FormInstance } from 'antd';
import React from 'react';

import FormInput from '@/app/_components/form/FormInput';
import { InputM2A } from '@/app/_types/components/form';
import { InputM2AUnion } from '@/app/_types/components/form';

import Styles from './RiskAnalysisInitiative.module.scss';

type Props = {
  form: FormInstance<any>;
  formInputs: InputM2AUnion[];
};

const RiskAnalysisInitiative = ({ form, formInputs }: Props) => {
  const groupFormInputs = (formInputs: InputM2AUnion[]) => {
    return formInputs.reduce(
      (acc, current) => {
        if ('content' in current.item) {
          acc.push({
            content: current.item.content,
            fields: [],
          });
        } else if (acc.length > 0) {
          acc[acc.length - 1].fields.push(current as InputM2A);
        }
        return acc;
      },
      [] as {
        content: string | null | undefined;
        fields: InputM2A[];
      }[],
    );
  };

  const groupedInputs = groupFormInputs(formInputs);

  return (
    <>
      <div className="container">
        <div className={Styles.riskInitiativeWraper}>
          <div className={Styles.riskInitiativeForm}>
            {groupedInputs.map((group, groupIndex) => (
              <div
                className={Styles.riskInitiativeFormContainer}
                key={groupIndex}
              >
                {group.content && (
                  <div
                    className="headingElement full-width"
                    dangerouslySetInnerHTML={{ __html: group.content }}
                  />
                )}
                <div className={Styles.riskInitiativeFormField}>
                  {group.fields.map((field, fieldIndex) => (
                    <FormInput key={fieldIndex} form={form} input={field} />
                  ))}
                </div>
              </div>
            ))}
          </div>
          <Divider className={Styles.formDivider} />
        </div>
      </div>
    </>
  );
};

export default RiskAnalysisInitiative;
