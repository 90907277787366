'use client';

import React, { useRef, useState } from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { Button } from '@/app/_types/components/button';
import { LocationCard } from '@/app/_types/components/location-card';
import { Vendor } from '@/app/_types/components/vendor';
import { VendorBranch } from '@/app/_types/components/vendor-branch';
import { VendorCategory } from '@/app/_types/components/vendor-category';
import { VendorOffer } from '@/app/_types/components/vendor-offering';
import { FilesMeta } from '@/app/_types/file';
// import { extractCityFromPlaceAddress } from '@/app/_utils/text';

import ResultListing from '../company/ResultListing';

import Styles from './SearchByService.module.scss';
import ServicesButtons from './ServicesButtons';
import ServicesSelector from './ServicesSelector';

type Step = {
  title: string;
  isCompleted: boolean;
};

type ServiceProps = {
  preText?: string;
  title?: string;
  paragraph?: string;
  continueText?: string;
  backToStartUrl?: Button;
  gridItemsData?: VendorCategory;
  vendorOffering?: VendorOffer;
  vendor?: Vendor;
  vendorBranch?: VendorBranch;
  filesMeta: FilesMeta;
  serviceResultPretext?: string;
  serviceResultTitle?: string;
  serviceResultParagraph?: string;
  headOffice?: LocationCard | null;
  noVendorIcon: string;
  noVendorTitle: string;
  noVendorParagraph: string;
  backToStartButton: Button;
  step1PreText?: string;
  step1Title?: string;
  step1Paragraph?: string;
  step2PreText?: string;
  step2Title?: string;
  step2Paragraph?: string;
  step3PreText?: string;
  step3Title?: string;
  step3Paragraph?: string;
};

const SearchByService = (props: ServiceProps) => {
  const {
    gridItemsData,
    vendorBranch = [],
    backToStartUrl,
    filesMeta,
    serviceResultPretext,
    serviceResultTitle,
    serviceResultParagraph,
    headOffice,
    noVendorIcon,
    noVendorTitle,
    noVendorParagraph,
    backToStartButton,
    step1PreText,
    step1Title,
    step1Paragraph,
    // step2PreText,
    // step2Title,
    // step2Paragraph,
    step3PreText,
    step3Title,
    step3Paragraph,
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [selectedCategoryType, setSelectedCategoryType] = useState<
    string | null
  >(null);
  // const [address, setAddress] = useState('');
  const [selectedOptions] = useState<{
    [key: string]: string[];
  }>({});
  const [allSelectedIds, setAllSelectedIds] = useState<number[]>([]);

  const steps: Step[] = [
    { title: 'Step 1', isCompleted: false },
    { title: 'Step 2', isCompleted: false },
    { title: 'Step 3', isCompleted: false },
  ];

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return {
          preText: step1PreText,
          title: step1Title,
          paragraph: step1Paragraph,
        };
      // uncomment when google maps is back
      // case 1:
      //   return {
      //     preText: step2PreText,
      //     title: step2Title,
      //     paragraph: step2Paragraph,
      //   };
      case 1:
        return {
          preText: step3PreText,
          title: step3Title,
          paragraph: step3Paragraph,
        };
      default:
        return { preText: '', title: '', paragraph: '' };
    }
  };

  const {
    preText: currentPreText,
    title: currentTitle,
    paragraph: currentParagraph,
  } = getStepContent();

  const handleCategorySelect = (categoryType: string) => {
    setSelectedCategoryType(categoryType);
  };

  // uncomment when google maps is back
  // const handleAddressChange = (value: string) => {
  //   setAddress(value);
  // };

  const handleSelectedOptionsChange = (newOptions: {
    [key: string]: string | string[];
  }) => {
    const newAllSelectedIds = Object.values(newOptions).flatMap((value) =>
      Array.isArray(value) ? value : [value],
    );

    setAllSelectedIds(newAllSelectedIds.map(Number));
  };

  const stepWizardRef = useRef<HTMLDivElement | null>(null);

  const scrollToStepWizard = () => {
    const offset = 120;

    if (stepWizardRef.current) {
      const scrollPosition = stepWizardRef.current.offsetTop - offset;

      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  };

  const handleStepClick = (step: number) => {
    setActiveStep(step);
    scrollToStepWizard();
  };

  const handleContinue = (step: number) => {
    if (activeStep <= steps.length - 1) {
      setActiveStep(step + 1);
    }
    // uncomment when google maps is back
    // if (activeStep === 2) {
    //   setShowResult(true);
    // }
    if (activeStep === 1) {
      setShowResult(true);
    }
    scrollToStepWizard();
  };

  const dummyVendor = gridItemsData
    ?.filter((vendor) => vendor.category_type === selectedCategoryType)
    .filter((vendor) => vendor.offerings && vendor.offerings.length > 0);

  const filterredDummyData = dummyVendor?.flat();

  const filteredVendorBranchOne = vendorBranch.filter((branch) => {
    const branchOfferingIds = branch.branch_offerings?.map((offering) =>
      offering.vendor_offerings_id?.id.toString(),
    );

    return allSelectedIds?.every(
      (id) => branchOfferingIds && branchOfferingIds.includes(id.toString()),
    );
  });

  const filteredVendorBranch = filteredVendorBranchOne.filter((branch) => {
    // if (address) {
    //   if (!branch.address) {
    //     return false;
    //   }

    //   const city = extractCityFromPlaceAddress(address);

    //   if (!city) {
    //     return false;
    //   }
    //   if (!branch.address.toLowerCase().includes(city.toLowerCase())) {
    //     return false;
    //   }
    // }

    const branchOfferingNames = branch.branch_offerings?.map((offering) => {
      return offering.vendor_offerings_id?.name || '';
    });

    const flattenedOptions = (
      selectedOptions[selectedCategoryType || ''] ?? []
    ).flat();

    return flattenedOptions?.every((option) =>
      branchOfferingNames?.includes(option),
    );
  });

  const filteredVendorBranchWithoutOfferings = (
    filteredVendorBranch.length > 0
      ? filteredVendorBranch
      : filteredVendorBranchOne
  ).map(({ branch_offerings: _constants, ...rest }) => rest);

  return (
    <div className={Styles.wizardCard}>
      <div className={Styles.sectionHeadingContainer}>
        <div className="headingElement">
          {currentPreText && <h3 className="subTitle">{currentPreText}</h3>}
          {currentTitle && <h2 className="titleXl">{currentTitle}</h2>}
        </div>

        {currentParagraph && (
          <div className={Styles.sectionDescription}>{currentParagraph}</div>
        )}
      </div>

      <div className={Styles.stepWizard} ref={stepWizardRef}>
        <div className={Styles.stepsContainer}>
          {steps.map((step, index) => {
            const isCompleted = index < activeStep;
            const isActive = index === activeStep;

            return (
              <div
                key={index}
                className={`${Styles.step} ${isCompleted ? Styles.completed : ''} ${isActive ? Styles.active : ''}`}
              >
                <div
                  className={Styles.stepNumber}
                  onClick={() => handleStepClick(index)}
                >
                  {index + 1}
                </div>
              </div>
            );
          })}
        </div>
        {!showResult && (
          <>
            <div className={Styles.stepContent}>
              {activeStep === 0 && (
                <ServicesButtons
                  services={gridItemsData}
                  filesMeta={filesMeta}
                  onSelectCategory={handleCategorySelect}
                />
              )}
              {/* uncomment when google maps is back */}
              {/* {activeStep === 1 && (
                <LocationMap
                  initialValue={address}
                  onChange={handleAddressChange}
                />
              )} */}
              {activeStep === 1 && (
                <ServicesSelector
                  vendorOffering={filterredDummyData}
                  onSelectedOptionsChange={handleSelectedOptionsChange}
                />
              )}
            </div>

            <div className={Styles.wizardFooter}>
              <div className={Styles.wizardBtnNavigation}>
                {activeStep === 0 ? (
                  <ButtonCustom
                    className="btnPrimaryOutline"
                    href={backToStartUrl?.url ?? undefined}
                  >
                    Back
                  </ButtonCustom>
                ) : null}
                {activeStep > 0 && (
                  <ButtonCustom
                    className="btnPrimaryOutline"
                    onClick={() => handleStepClick(activeStep - 1)}
                  >
                    Back
                  </ButtonCustom>
                )}

                <ButtonCustom onClick={() => handleContinue(activeStep)}>
                  Continue
                </ButtonCustom>
              </div>
            </div>
          </>
        )}

        {showResult && (
          <ResultListing
            vendorBranch={filteredVendorBranchWithoutOfferings ?? []}
            backToStartUrl={backToStartUrl}
            preText={serviceResultPretext}
            title={serviceResultTitle}
            paragraph={serviceResultParagraph}
            headOffice={headOffice}
            noVendorIcon={noVendorIcon}
            noVendorTitle={noVendorTitle}
            noVendorParagraph={noVendorParagraph}
            backToStartButton={backToStartButton}
          />
        )}
      </div>
    </div>
  );
};

export default SearchByService;
