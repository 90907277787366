import { Form } from 'antd';
import React, { useEffect, useState } from 'react';

import CheckBoxCustom from '@/app/_components/checkbox/CheckBoxCustom';
import { HeaderComponent } from '@/app/_types/components/header-component';
import { Product } from '@/app/_types/components/product';

import StepWrapper from '../step-wrapper/StepWrapper';

import styles from './ProductOptionSelection.module.scss';

interface ProductOptionProps {
  onBack: () => void;
  data: any;
  handleFinalStep: (products: Product[]) => void;
  mainCategory: string;
  header?: HeaderComponent;
  handleClose: () => void;
}
const ProductOptionSelection = ({
  onBack,
  data,
  handleFinalStep,
  mainCategory,
  header,
  handleClose,
}: ProductOptionProps) => {
  const [questionOptions, setQuestionOptions] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<any>([]);

  const handleCheckboxChange = (id: string) => {
    setSelectedItems((prevSelectedItems: string[]) => {
      if (prevSelectedItems.includes(id)) {
        return prevSelectedItems.filter((item) => item !== id);
      } else {
        return [...prevSelectedItems, id];
      }
    });
  };

  const handleSetFinalData = () => {
    const matchedOptions = questionOptions.filter((option: any) =>
      selectedItems.includes(option.id),
    );

    handleFinalStep(matchedOptions);
  };

  const handleNext = () => {
    if (selectedItems.length > 0) {
      handleSetFinalData();
    }
  };

  useEffect(() => {
    if (data.children) {
      setQuestionOptions(data.children);
    }
  }, []);

  return (
    <div>
      <StepWrapper
        isShowBack={true}
        onNext={handleNext}
        onBack={onBack}
        stepTitle={data.question}
        stepTitleTwo={data.question_title_2}
        stepDescription={data.question_help_text}
        isDisableNext={selectedItems.length < 1}
        mainCategory={mainCategory}
        header={header}
        handleClose={handleClose}
      >
        <div className={styles.checkboxListWrapper}>
          {questionOptions &&
            questionOptions.map((option) => {
              return (
                <Form.Item
                  key={option.id}
                  name={option.id}
                  className="customInput"
                >
                  <CheckBoxCustom
                    name={option.id}
                    label={option.option_title}
                    description={option.option_sub_title}
                    checked={selectedItems.includes(option.id)}
                    onChange={() => handleCheckboxChange(option.id)}
                  />
                </Form.Item>
              );
            })}
        </div>
      </StepWrapper>
    </div>
  );
};

export default ProductOptionSelection;
