'use client';

import cn from 'classnames';
import { usePathname } from 'next/navigation';
import React, { useEffect, useRef } from 'react';
import slugify from 'react-slugify';

import ProductCard from '@/app/_components/product-card/ProductCard';
import { FilesMeta } from '@/app/_types/file';

import Styles from './ServiceSection.module.scss';

type UpdatedServiceCardsType = {
  id: number;
  title?: string | null;
  paragraph?: string | null;
  icon?: string | null;
  url?: string | null;
};

type ServiceSectionTypes = {
  providerList: UpdatedServiceCardsType[];
  title?: string | null;
  preText?: string | null;
  filesMeta?: FilesMeta;
};
const ServiceSection = (props: ServiceSectionTypes) => {
  const { preText, title, providerList, filesMeta = {} } = props;

  const pathName = usePathname();
  const serviceProviderRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const offset = 120;
    const hash = window.location.hash;
    const activeSection = hash.replace('#', '');

    if (activeSection === slugify(title) && serviceProviderRef.current) {
      const scrollPosition = serviceProviderRef.current.offsetTop - offset;

      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }, [pathName]);

  return (
    <div
      className={cn('pageSection', Styles.pageSection)}
      id={slugify(title)}
      ref={serviceProviderRef}
    >
      <div className="container">
        <div className={Styles.serviceSectionWrapper}>
          <div className={Styles.sectionHeadingContainer}>
            <div className={Styles.sectionLeftContent}>
              <div className="headingElement">
                {preText && <h3 className="subTitle">{preText}</h3>}
                {title && <h2 className="titleXl">{title}</h2>}
              </div>
            </div>
          </div>
          {providerList && (
            <div className={Styles.cardsList}>
              {providerList.map((card, index) => (
                <ProductCard key={index} {...card} filesMeta={filesMeta} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
