'use client';

import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import { Partner as PartnerType } from '@/app/_types/components/partner';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './Partners.module.scss';

type PartnerProps = PartnerType & {
  filesMeta: FilesMeta;
};

const Partners = (props: PartnerProps) => {
  const {
    title,
    pre_text,
    paragraph,
    partner_images,
    partner_images_redirect,
    filesMeta,
  } = props;

  return (
    <div className="pageSection">
      <div className={Styles.partnersWrapper}>
        <div className="container">
          <div className={Styles.sectionHeadingContainer}>
            <div className={Styles.sectionLeftContent}>
              <div className="headingElement">
                {pre_text && <h3 className="subTitle">{pre_text}</h3>}
                {title && <h2 className="titleXl">{title}</h2>}
              </div>
            </div>
            <div className={Styles.sectionRightContent}>
              {paragraph && (
                <div
                  className={Styles.sectionDescription}
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                ></div>
              )}
            </div>
          </div>
        </div>
        {partner_images?.length && (
          <div className={Styles.sliderWrapper}>
            <Swiper
              spaceBetween={40}
              slidesPerView={2}
              grabCursor={true}
              freeMode={true}
              loop={true}
              speed={3000}
              autoplay={{
                delay: 10,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 5,
                },
                1024: {
                  slidesPerView: 7,
                },
              }}
              modules={[Autoplay]}
              className="mySwiper"
            >
              {partner_images.map((partner, index) => (
                <SwiperSlide key={index}>
                  <Link
                    href={partner_images_redirect?.permalink ?? ''}
                    className={Styles.partnerLink}
                  >
                    <Image
                      src={
                        getDirectusAssetURI(partner?.directus_files_id ?? '') ||
                        ''
                      }
                      alt={getAltProperty(
                        filesMeta,
                        partner?.directus_files_id ?? '',
                        'Partner image',
                      )}
                      width={336}
                      height={188}
                      quality={100}
                      className={Styles.partnerImage}
                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </div>
    </div>
  );
};

export default Partners;
