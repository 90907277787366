'use client';
import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import slugify from 'react-slugify';

import { CTABanner } from '@/app/_types/components/cta-banner';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';
import { formatDate } from '@/app/_utils/date-format';
import { sanitizeAbsolutePath } from '@/app/_utils/text';

import Styles from './CtaBanner.module.scss';

type Props = CTABanner & {
  articleTitle?: string;
  articleImage?: string;
  articleCreated_at?: string;
  articleAuthor?: string;
  date?: string;
  author?: string;
  baseUrl?: string;
  filesMeta?: FilesMeta;
};

const CtaBanner = (props: Props) => {
  const {
    title,
    title_2,
    paragraph,
    articleTitle,
    image,
    date,
    author,
    baseUrl = '',
    filesMeta = {},
  } = props;

  const slug = slugify(articleTitle);

  return (
    <div className={Styles.ctaBannerWrapper}>
      <div className="container">
        <div className={Styles.ctaBannerContent}>
          <div className="headingElement">
            {title && (
              <h1
                className={cn(
                  'titleXXl text-primary strong',
                  Styles.ctaBannerTitle,
                )}
              >
                {title && <span className="light">{title}</span>}
                {title_2}
              </h1>
            )}
          </div>

          {paragraph && (
            <div
              className={cn(Styles.description, 'editorDescription')}
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          )}
        </div>
      </div>
      {articleTitle && (
        <div className="container">
          <Link
            href={`${sanitizeAbsolutePath(baseUrl)}${slug}`}
            className={Styles.bannerCard}
          >
            <div className={Styles.bannerCardContent}>
              <div className="headingElement">
                {articleTitle && <h2 className="titleXl">{articleTitle}</h2>}
              </div>

              <ul className={Styles.metaData}>
                {date && <li>{formatDate(date)}</li>}
                {author && <li>{author}</li>}
              </ul>
            </div>

            {image && (
              <div className={Styles.bannerImageWrapper}>
                <Image
                  src={getDirectusAssetURI(image) ?? ''}
                  alt={getAltProperty(filesMeta, image, 'CTA Image')}
                  height={390}
                  width={616}
                  className={Styles.bannerImage}
                />
              </div>
            )}
          </Link>
        </div>
      )}
    </div>
  );
};

export default CtaBanner;
