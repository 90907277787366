'use client';

import Image from 'next/image';
import React, { useRef } from 'react';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import arrowIcon from '@/app/_images/arrow-icon.png';
import { CoverageComponent } from '@/app/_types/components/coverage';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './IconCardSection.module.scss';

SwiperCore.use([Navigation]);

type IconSectionProps = CoverageComponent & {
  filesMeta: FilesMeta;
};

const IconCardSection = (props: IconSectionProps) => {
  const { title, paragraph, pre_text, coverage, coverage_title, filesMeta } =
    props;
  const swiperRef = useRef<SwiperCore | null>(null);

  const handleSwipeClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const groupedProducts = [];

  if (coverage?.length) {
    for (let i = 0; i < coverage.length; i += 4) {
      groupedProducts.push(coverage.slice(i, i + 4));
    }
  }

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.iconCardWrapper}>
          <div className={Styles.iconCardContentContainer}>
            <div className={Styles.iconCardContentHeadingSection}>
              {title && (
                <div className="headingElement">
                  {pre_text && <h3 className="subTitle">{pre_text}</h3>}
                  {title && <h2 className="titleXl">{title}</h2>}
                </div>
              )}
            </div>
            {paragraph && (
              <div
                className={Styles.iconCardContentDetails}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            )}
          </div>

          {coverage && (
            <div className={Styles.coverageContainer}>
              {coverage_title && (
                <div className="headingElement">
                  <h4 className="titleSm">{coverage_title}</h4>
                </div>
              )}
              <div className={Styles.iconCardLists}>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={40}
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                  className={Styles.swiper}
                  loop={true}
                  breakpoints={{
                    768: {
                      slidesPerView: 1,
                    },
                  }}
                >
                  {groupedProducts.map((product, index) => (
                    <SwiperSlide key={index}>
                      <div className={Styles.iconCardSlide}>
                        {product.map(({ coverage_card_id }, i) => (
                          <div className={Styles.iconCard} key={i}>
                            <Image
                              src={
                                getDirectusAssetURI(
                                  coverage_card_id?.icon ?? '',
                                ) || ''
                              }
                              alt={getAltProperty(
                                filesMeta,
                                coverage_card_id?.icon ?? '',
                                'Banner image',
                              )}
                              width={60}
                              height={60}
                            />
                            <div className={Styles.iconCardBody}>
                              <h4 className={Styles.title}>
                                {coverage_card_id.title}
                              </h4>
                              {coverage_card_id.description && (
                                <div
                                  className={Styles.description}
                                  dangerouslySetInnerHTML={{
                                    __html: coverage_card_id.description,
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                {groupedProducts.length > 1 && (
                  <div className="container">
                    <div className={Styles.swipeWrapper}>
                      <button
                        className={Styles.swiperIconBtn}
                        onClick={handleSwipeClick}
                      >
                        Swipe
                        <Image
                          src={arrowIcon}
                          alt="arrow-icon"
                          height={18}
                          width={18}
                        />
                      </button>
                    </div>
                  </div>
                )}

                <div className={Styles.desktopGrid}>
                  {coverage.map(({ coverage_card_id }, index) => (
                    <div key={index} className={Styles.iconCard}>
                      <Image
                        src={
                          getDirectusAssetURI(coverage_card_id?.icon ?? '') ||
                          ''
                        }
                        alt={getAltProperty(
                          filesMeta,
                          coverage_card_id?.icon ?? '',
                          'Banner image',
                        )}
                        width={60}
                        height={60}
                      />
                      <div className={Styles.iconCardBody}>
                        <h4 className={Styles.title}>
                          {coverage_card_id?.title}
                        </h4>

                        {coverage_card_id.description && (
                          <div
                            className={Styles.description}
                            dangerouslySetInnerHTML={{
                              __html: coverage_card_id.description,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IconCardSection;
