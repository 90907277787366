'use client';

import cn from 'classnames';
import Image from 'next/image';
import React, { useRef } from 'react';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import arrowIcon from '@/app/_images/arrow-icon.png';
import { AboutUsVariation2 } from '@/app/_types/about-us';

import Styles from './Timeline.module.scss';

import 'swiper/css';

SwiperCore.use([Navigation]);

type TimelineProps = {
  history_title: string;
  history: AboutUsVariation2['history'];
};

const TimeLine = (props: TimelineProps) => {
  const { history_title, history } = props;

  const swiperRef = useRef<SwiperCore | null>(null);

  const handleSwipeClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <div className="pageSection" id="history">
      <div className="container">
        <div className={Styles.timelineWrapper}>
          <div className={Styles.leftContent}>
            {history_title && <p className={Styles.heading}>{history_title}</p>}
          </div>

          <div className={Styles.rightContent}>
            <div className={Styles.rightContentContainer}>
              {history_title && (
                <div className="headingElement">
                  <h2 className="titleMd medium">{history_title}</h2>
                </div>
              )}

              {history && (
                <div className={Styles.cardContentWrapper}>
                  <Swiper
                    onSwiper={(swiper) => {
                      swiperRef.current = swiper;
                    }}
                    slidesPerView={1}
                    spaceBetween={40}
                    loop={true}
                    breakpoints={{
                      640: {
                        slidesPerView: 2,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                      },
                      1024: {
                        slidesPerView: 'auto',
                        spaceBetween: 80,
                      },
                    }}
                  >
                    {history.map((history, index) => (
                      <SwiperSlide key={index}>
                        <h6 className={Styles.cardYear}>
                          {history.history_tab_id.year &&
                            history.history_tab_id.year}
                        </h6>
                        <p className={Styles.cardDescription}>
                          {history.history_tab_id.paragraph}
                        </p>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              )}

              <div className={Styles.swipeWrapper}>
                <button
                  className={cn(Styles.swiperIcon, 'text-primary medium')}
                  onClick={handleSwipeClick}
                >
                  Swipe
                  <Image
                    src={arrowIcon}
                    alt="arrow-icon"
                    height={18}
                    width={18}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeLine;
