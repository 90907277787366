'use client';
import Image from 'next/image';
import React from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './NoResultsFound.module.scss';

type NoResultsFoundProps = {
  image?: string | null;
  title: string;
  paragraph?: string | null;
  buttonText?: string | null;
  buttonURL?: string | null;
  filesMeta: FilesMeta;
};

const NoResultsFound = (props: NoResultsFoundProps) => {
  return (
    <div className={Styles.noResultsWrapper}>
      {props.image && (
        <Image
          src={getDirectusAssetURI(props.image) || ''}
          alt={getAltProperty(props.filesMeta, props.image ?? '', 'Warning')}
          height={160}
          width={160}
        />
      )}
      <div className={Styles.noResultsContent}>
        <div className="headingElement">
          <h2 className="titleLg">{props.title}</h2>
        </div>
        {props.paragraph && <p>{props.paragraph}</p>}
      </div>
      {props.buttonText && props.buttonURL && (
        <ButtonCustom btnText={props.buttonText} href={props.buttonURL} />
      )}
    </div>
  );
};

export default NoResultsFound;
