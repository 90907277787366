'use client';
import Image from 'next/image';
import React, { useEffect } from 'react';

import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './ThankYou.module.scss';

type Props = {
  submitted_title?: string | null;
  submitted_paragraph?: string | null;
  submitted_image?: string | null;
  filesMeta?: FilesMeta;
};
const ThankYou = (props: Props) => {
  const { submitted_title, submitted_paragraph, submitted_image, filesMeta } =
    props;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className={Styles.thankYouWraper}>
      {submitted_image && (
        <Image
          src={getDirectusAssetURI(submitted_image) ?? ''}
          alt={
            filesMeta
              ? getAltProperty(
                  filesMeta,
                  submitted_image ?? '',
                  'Form submitted icon',
                )
              : 'Form submitted icon'
          }
          height={160}
          width={160}
          className={Styles.thankYouImage}
        />
      )}
      <div className={Styles.thankYouContent}>
        <div className="headingElement">
          {submitted_title && <h2 className="titleLg"> {submitted_title}</h2>}
        </div>
        {submitted_paragraph && (
          <div
            dangerouslySetInnerHTML={{
              __html: submitted_paragraph,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ThankYou;
