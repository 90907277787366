'use client';

import { AutoComplete, Button, Collapse, Form, Input, Spin } from 'antd';
import cn from 'classnames';
import Image from 'next/image';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import slugify from 'react-slugify';

import CheckBoxCustom from '@/app/_components/checkbox/CheckBoxCustom';
import {
  allSearchCategories,
  SearchCategory,
  SearchFlow,
} from '@/app/_constants/vars.constants';
import ChevronDown from '@/app/_icons/chevron-down.svg';
import FilterIcon from '@/app/_icons/filter.svg';
import SearchIcon from '@/app/_icons/search.svg';
import { FilesMeta } from '@/app/_types/file';
import {
  CategorisedSearchResults,
  SearchResult,
} from '@/app/_types/search-results-page';
import { search } from '@/app/actions';

import NoResultsFound from './NoResultsFound';
import Styles from './Results.module.scss';

type ResultsProps = {
  title: string;
  preText?: string | null;
  searchSuggestions?: string[] | null;
  query?: string | null;
  initialResults: CategorisedSearchResults;
  initialResultsCount?: number;
  noResultsTitle: string;
  noResultsImage?: string | null;
  noResultsParagraph?: string | null;
  noResultsButtonText?: string | null;
  noResultsButtonURL?: string | null;
  filesMeta: FilesMeta;
};

const Results = (props: ResultsProps) => {
  const { title, initialResultsCount, initialResults, filesMeta } = props;

  const [form] = Form.useForm();
  const [results, setResults] = useState<CategorisedSearchResults>(
    initialResults || {},
  );
  const [resultsCount, setResultsCount] = useState<number>(
    initialResultsCount || 0,
  );
  const [searchTerm, setSearchTerm] = useState<string>(props.query || '');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchSuggestionOptions, setSearchSuggestionOptions] = useState<
    { value: string }[]
  >([]);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [filterCategories, setFilterCategories] =
    useState<string[]>(allSearchCategories);
  const filterMenuRef = useRef<HTMLDivElement>(null);
  const filterBtnRef = useRef<HTMLAnchorElement>(null);

  const uniqueCategories = useMemo(() => {
    if (results) {
      return Object.keys(results);
    }
    return [];
  }, [results]);

  const toggleFilter = () => {
    setIsFilterOpen((prev) => !prev);
  };

  const onFilterItemChange = (itemName: string, checked: boolean) => {
    setFilterCategories((prev) =>
      checked ? [...prev, itemName] : prev.filter((item) => item !== itemName),
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterMenuRef.current &&
        !filterMenuRef.current.contains(event.target as Node) &&
        filterBtnRef.current &&
        !filterBtnRef.current.contains(event.target as Node)
      ) {
        setIsFilterOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const doSearch = useCallback(
    async (term: string) => {
      const newResults: CategorisedSearchResults = {};
      let newResultsCount = 0;
      const categories: SearchCategory[] = [];
      const promises: Promise<{
        success: boolean;
        results?: SearchResult | null;
        error?: string;
      }>[] = [];

      // search each selected category
      for (const filter of filterCategories) {
        switch (filter) {
          case SearchCategory.Insurance:
            categories.push(filter);
            promises.push(search(SearchFlow.Insurance, term));
            break;
          case SearchCategory.Media:
            categories.push(filter);
            promises.push(search(SearchFlow.Media, term));
            break;
          case SearchCategory.Resources:
            categories.push(filter);
            promises.push(search(SearchFlow.Resources, term));
            break;
          case SearchCategory.AgentsAndUMAs:
            categories.push(filter);
            promises.push(search(SearchFlow.AgentsAndUMAs, term));
            break;
          case SearchCategory.Pages:
            categories.push(filter);
            promises.push(search(SearchFlow.Pages, term));
            break;
        }
      }

      // do all calls simultaneously
      if (promises.length) {
        setIsSearching(true);

        const resolvedPromises = await Promise.all(promises);

        setIsSearching(false);

        for (let i = 0; i < resolvedPromises.length; ++i) {
          if (resolvedPromises[i].success && resolvedPromises[i].results) {
            newResults[categories[i]] = resolvedPromises[i]
              .results as SearchResult;
            newResultsCount += newResults[categories[i]].totalCount || 0;
          }
        }
      }

      setResultsCount(newResultsCount);
      setResults(newResults);
    },
    [filterCategories],
  );

  const handleAutocompleteSearch = (term: string) => {
    setSearchTerm(term);
    if (props.searchSuggestions) {
      const filteredSuggestions = props.searchSuggestions
        .filter((suggestion) =>
          suggestion.toLowerCase().includes(term.toLowerCase()),
        )
        .map((suggestion) => ({ value: suggestion }));

      setSearchSuggestionOptions(filteredSuggestions);
    }
  };

  const handleAutocompleteSuggestionSelect = (term: string) => {
    setSearchTerm(term);
    doSearch(term);
  };

  const filteredResults = useMemo(() => {
    if (!results) return {};
    if (filterCategories.length === 0) return {};

    const categories = uniqueCategories.filter((categoryName) =>
      filterCategories.includes(categoryName),
    );
    const filtered: CategorisedSearchResults = {};

    for (const category of categories) {
      filtered[category] = results[category];
    }
    return filtered;
  }, [results, filterCategories]);

  let collapseIndex = 0;
  const collapseItems = Object.keys(filteredResults)
    .map((category) => {
      const categoryItems = filteredResults[category] || null;

      if (!categoryItems || categoryItems.results.length === 0) {
        return null;
      }

      ++collapseIndex;

      return {
        key: `key-${collapseIndex}`,
        label: category,
        children: categoryItems.results.map((item, index) => (
          <div
            key={`${slugify(category)}-${index}`}
            className={Styles.resultItem}
          >
            <a href={item.permalink} className={Styles.resultTitle}>
              {item.title}
            </a>
            <div
              className={Styles.resultParagraph}
              dangerouslySetInnerHTML={{__html: item.paragraph || ''}}
            />
          </div>
        )),
      };
    })
    .filter((item) => item != null);

  return (
    <div className="pageSectionSmall">
      <div className="container">
        <div className={Styles.resultsWrapper}>
          <div className="headingElement text-primary">
            <h1 className="titleLgXl strong mb-2">{title}</h1>
            {resultsCount != null && resultsCount > 0 && (
              <p className="light">Total Search Results: {resultsCount}</p>
            )}
          </div>
          <div className={Styles.resultsContainer}>
            <div className={Styles.searchWrapper}>
              <div className={Styles.searchContainer}>
                <Form
                  form={form}
                  onFinish={(values) => doSearch(values['search_term'])}
                  className={Styles.searchForm}
                >
                  <div className={Styles.searchBox}>
                    <Form.Item
                      noStyle
                      name={'search_term'}
                      initialValue={searchTerm}
                    >
                      <AutoComplete
                        options={searchSuggestionOptions}
                        onSearch={handleAutocompleteSearch}
                        onSelect={handleAutocompleteSuggestionSelect}
                        suffixIcon={isSearching ? <Spin /> : null}
                      >
                        <Input
                          placeholder="Search..."
                          className={cn(Styles.searchBoxInput, 'customInput')}
                        />
                      </AutoComplete>
                    </Form.Item>
                    <Button className={Styles.searchBtn} onClick={form.submit}>
                      <Image
                        src={SearchIcon}
                        alt="Search"
                        height={14}
                        width={14}
                      />
                    </Button>
                  </div>
                </Form>
                <a
                  onClick={toggleFilter}
                  className={cn(
                    Styles.filterBtn,
                    resultsCount === 0 && results === null && Styles.disabled,
                  )}
                  ref={filterBtnRef}
                >
                  <span className={Styles.btnText}>
                    Filter {`(${filterCategories?.length})`}
                  </span>
                  <Image
                    src={FilterIcon}
                    alt="Filter"
                    className={Styles.btnIcon}
                    height={14}
                    width={14}
                  />
                </a>
              </div>
              <div
                ref={filterMenuRef}
                className={cn(Styles.filterMenu, isFilterOpen && Styles.open)}
              >
                {allSearchCategories.map((category, index) => (
                  <div key={index} className={Styles.filterMenuItem}>
                    <div id={category} className="customInput mb-0">
                      <CheckBoxCustom
                        name={category}
                        label={category}
                        onChange={(e) =>
                          onFilterItemChange(category, e.target.checked)
                        }
                        checked={filterCategories.includes(category)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {resultsCount !== 0 && results !== null && (
              <div className={Styles.resultsListings}>
                <Collapse
                  accordion
                  expandIconPosition={'end'}
                  bordered={false}
                  ghost={true}
                  className={Styles.collapseSection}
                  items={collapseItems}
                  defaultActiveKey="key-1"
                  expandIcon={({ isActive }) => (
                    <Image
                      src={ChevronDown}
                      alt="Chevron Down"
                      className={isActive ? Styles.openBox : Styles.closeBox}
                      height={16}
                      width={16}
                    />
                  )}
                />
              </div>
            )}
          </div>

          {resultsCount === 0 && (
            <NoResultsFound
              image={props.noResultsImage}
              title={props.noResultsTitle}
              paragraph={props.noResultsParagraph}
              buttonText={props.noResultsButtonText}
              buttonURL={props.noResultsButtonURL}
              filesMeta={filesMeta}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Results;
