'use client';
import { Breadcrumb } from 'antd';
import Link from 'next/link';
import React from 'react';

import { Breadcrumb as BreadcrumbType } from '@/app/_types/breadcrumbs';

const BreadcrumbCustom = ({ items }: { items: BreadcrumbType[] }) => {
  function itemRender(currentRoute, items) {
    const isLast = currentRoute?.path === items[items.length - 1]?.path;

    return isLast ? (
      <span>{currentRoute.title}</span>
    ) : (
      <Link href={currentRoute.path}>{currentRoute.title}</Link>
    );
  }

  return (
    <div className="container customBreadcrumb hidden-mob-tab">
      <Breadcrumb itemRender={itemRender} items={items} />
    </div>
  );
};

export default BreadcrumbCustom;
