'use client';
import Image from 'next/image';
import React, { useRef, useState } from 'react';

import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import ButtonCustom from '../button/ButtonCustom';

import Styles from './CustomTabs.module.scss';

type CustomTabsProps = {
  tabsList: {
    id: number;
    title?: string | null;
    pre_text?: string | null;
    subtitle?: string | null;
    icon?: string | null;
    paragraph?: string | null;
    main_image?: string | null;
    learn_more_cta_text?: string | null;
    learn_more_cta_url?: string | null;
    filesMeta?: FilesMeta;
  }[];
  filesMeta?: FilesMeta;
};

const CustomTabs = ({ tabsList, filesMeta = {} }: CustomTabsProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const content = tabsList[activeTab];

  const handleTabClick = (index: number) => {
    setActiveTab(index);

    tabRefs.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  return (
    <div className={Styles.customTabsWrapper}>
      <div className={Styles.tabBar}>
        {tabsList.map((tab, index) => (
          <button
            key={index}
            ref={(el) => {
              if (el) {
                tabRefs.current[index] = el;
              }
            }}
            className={`${Styles.tabButton} ${activeTab === index ? Styles.active : ''}`}
            onClick={() => handleTabClick(index)}
          >
            <h3>{tab.title}</h3>
          </button>
        ))}
      </div>

      <div className={Styles.tabContent}>
        <Image
          src={getDirectusAssetURI(content?.main_image ?? '') || ''}
          alt={getAltProperty(
            filesMeta,
            content?.main_image ?? '',
            'Product image',
          )}
          width={540}
          height={214}
        />
        <h4>{content?.subtitle}</h4>
        <p>{content?.paragraph}</p>
        <div>
          {content?.learn_more_cta_text && (
            <ButtonCustom
              btnText={content?.learn_more_cta_text ?? ''}
              extraClass={Styles.ctaCardBtn}
              href={content?.learn_more_cta_url ?? ''}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomTabs;
