'use client';

import cn from 'classnames';
import { usePathname } from 'next/navigation';
import React, { useEffect, useRef } from 'react';
import slugify from 'react-slugify';

import { CopyComponent } from '@/app/_types/components/copy-component';
import { PartnerComponent } from '@/app/_types/product-landing';

import ButtonCustom from '../button/ButtonCustom';

import Styles from './PageContent.module.scss';

type PageContentTypes = (CopyComponent | PartnerComponent) & {
  smallContent?: boolean;
  btnVariant?: string;
};

const PageContent = (props: PageContentTypes) => {
  const {
    pre_text,
    title,
    paragraph,
    smallContent = false,
    cta_text,
    cta_url,
    cta_new_tab,
    btnVariant = 'btnPrimary',
  } = props;

  const pathName = usePathname();
  const partnershipRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const offset = 120;
    const hash = window.location.hash;
    const activeSection = hash.replace('#', '');

    if (activeSection === slugify(title) && partnershipRef.current) {
      const scrollPosition = partnershipRef.current.offsetTop - offset;

      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }, [pathName]);

  return (
    <div
      className={cn('pageSection', Styles.pageSection)}
      id={slugify(title)}
      ref={partnershipRef}
    >
      <div className="container">
        <div className={Styles.pageContentWrapper}>
          <div
            className={cn(
              Styles.pageContentContainer,
              smallContent && Styles.smallContent,
            )}
          >
            <div className={Styles.pageContentHeadingSection}>
              {title && (
                <div className="headingElement">
                  {pre_text && <h3 className="subTitle">{pre_text}</h3>}
                  {title && <h2 className="titleXl">{title}</h2>}
                </div>
              )}
            </div>
            {paragraph && (
              <div className={Styles.pageContentSection}>
                <div
                  className={Styles.pageContentDetails}
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                />
                {cta_text && (
                  <ButtonCustom
                    btnText={cta_text}
                    className={btnVariant}
                    href={cta_url ?? ''}
                    extraClass={Styles.pageContentBtn}
                    newTab={cta_new_tab}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContent;
