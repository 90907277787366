'use client';
import React, { useRef, useState } from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { DocumentNeededM2M } from '@/app/_types/components/document-needed';
import { FilesMeta } from '@/app/_types/file';

import Styles from './FormsTabs.module.scss';
import IconCardSection from './IconCardSection';

type FormsTabsProps = {
  documentList: DocumentNeededM2M[];
  title?: string | null;
  preText?: string | null;
  description?: string | null;
  filesMeta?: FilesMeta;
};

const FormsTabs = ({
  preText,
  title,
  description,
  documentList,
  filesMeta = {},
}: FormsTabsProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const content = documentList[activeTab];

  const handleTabClick = (index: number) => {
    setActiveTab(index);

    tabRefs.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.customTabsWrapper}>
          <div className={Styles.sectionHeadingContainer}>
            <div className={Styles.sectionLeftContent}>
              <div className="headingElement">
                {preText && <h3 className="subTitle">{preText}</h3>}
                {title && <h2 className="titleXl">{title}</h2>}
              </div>
            </div>
            {description && (
              <div
                className={Styles.sectionDescription}
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            )}
          </div>
          <div className={Styles.customTabsContainer}>
            <div className={Styles.tabBar}>
              {documentList?.map(({ documents_needed_id }, index) => (
                <button
                  key={index}
                  ref={(el) => {
                    if (el) {
                      tabRefs.current[index] = el;
                    }
                  }}
                  className={`${Styles.tabButton} ${activeTab === index ? Styles.active : ''}`}
                  onClick={() => handleTabClick(index)}
                >
                  <h3>
                    {documents_needed_id?.needed_forms &&
                      documents_needed_id?.needed_forms?.length > 0 &&
                      documents_needed_id?.title}
                  </h3>
                </button>
              ))}
            </div>

            <div className={Styles.tabContent}>
              <div className={Styles.tabContentInner}>
                <div className="headingElement">
                  <h4 className="titleMd text-primary">Needed forms</h4>
                </div>
                {content?.documents_needed_id?.needed_forms &&
                  content?.documents_needed_id?.needed_forms?.length > 0 && (
                    <IconCardSection
                      offerings={content?.documents_needed_id?.needed_forms}
                      filesMeta={filesMeta}
                    />
                  )}
              </div>
              <div>
                {content &&
                  content?.documents_needed_id?.cta_text &&
                  content?.documents_needed_id?.cta_url && (
                    <ButtonCustom
                      btnText={content?.documents_needed_id?.cta_text}
                      className="btnPrimaryOutline"
                      href={content?.documents_needed_id?.cta_url}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormsTabs;
