'use client';

import { Pagination, PaginationProps } from 'antd';
import React, { useEffect, useState } from 'react';

import MediaCard from '@/app/_components/media-card/MediaCard';
import { FilesMeta } from '@/app/_types/file';
import { MediaResourcesM2M } from '@/app/_types/media-page';

import NoResultsFound from '../forms-and-document/NoResultsFound';

import Styles from './MediaListing.module.scss';
import PaginationArrow from './PaginationArrow';

const PaginationItem: PaginationProps['itemRender'] = (
  _,
  type,
  originalElement,
) => {
  if (type === 'prev' || type === 'next') {
    return <PaginationArrow type={type} />;
  }
  return originalElement;
};

type Props = {
  media: MediaResourcesM2M[];
  resourceBaseUrl?: string;
  resourcesTitle?: string;
  resourcesPreText?: string | null;
  filesMeta?: FilesMeta;
};

const MediaListing = (props: Props) => {
  const {
    media,
    resourceBaseUrl,
    resourcesTitle,
    resourcesPreText,
    filesMeta,
  } = props;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize] = useState<number>(9);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentMedia = media.slice(startIndex, endIndex);

  useEffect(() => {
    setCurrentPage(1);
  }, [media]);

  return (
    <div className="container">
      <div className={Styles.mediaListingWrapper}>
        <div className={Styles.mediaListContainer}>
          <div className={Styles.sectionHeadingContainer}>
            <div className="headingElement">
              {resourcesPreText && (
                <h3 className="subTitle">{resourcesPreText}</h3>
              )}
              <h2 className="titleXl">{resourcesTitle}</h2>
            </div>
          </div>

          {!media || media?.length === 0 ? (
            <NoResultsFound />
          ) : (
            <>
              <div className={Styles.mediaList}>
                {currentMedia.map((item, index) => (
                  <MediaCard
                    key={index}
                    title={item?.media_resources_id.title}
                    content={item?.media_resources_id.description}
                    category={item?.media_resources_id.category?.title}
                    date={item.media_resources_id.date}
                    author={item?.media_resources_id.author}
                    slug={item?.media_resources_id.slug}
                    baseUrl={resourceBaseUrl}
                    image={item?.media_resources_id.preview_image}
                    document={item?.media_resources_id.document}
                    filesMeta={filesMeta}
                  />
                ))}
              </div>

              <Pagination
                align="end"
                className={Styles.customPagination}
                current={currentPage}
                pageSize={pageSize}
                total={media.length}
                onChange={(page) => setCurrentPage(page)}
                itemRender={PaginationItem}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaListing;
