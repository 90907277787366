'use client';
import React, { useMemo } from 'react';

import BreadcrumbCustom from '@/app/_components/breadcrumb/BreadcrumbCustom';
import CampaignSlider from '@/app/_components/campaign-slider/CampaignSlider';
import HelpCard from '@/app/_components/help-card/HelpCard';
import Steps from '@/app/_components/steps/Steps';
import { Breadcrumb } from '@/app/_types/breadcrumbs';
import { OfferingCardM2M } from '@/app/_types/components/offering-card';
import { FilesMeta } from '@/app/_types/file';
import { GuidelinePage } from '@/app/_types/guideline-page';

import CtaBanner from './CtaBanner';
import FormsAndDocuments from './FormsAndDocuments';
import FormsTabs from './FormsTabs';
import ServiceSection from './ServiceSection';

type GuidelinesPageProps = GuidelinePage & {
  filesMeta: FilesMeta;
  breadcrumbs?: Breadcrumb[] | null;
};

const GuidelinesPage = (props: GuidelinesPageProps) => {
  const {
    header,
    info_cards,
    step_title,
    step_pre_text,
    steps,
    documents_title,
    documents_pre_text,
    documents_group,
    document_cta_text,
    document_cta_url,
    base_url,
    submit_title,
    submit_pre_text,
    submit_description,
    documents_to_submit,
    contact_us_banner,
    service_cards,
    service_title,
    service_pre_text,
    filesMeta,
    breadcrumbs,
    campaigns,
  } = props;
  const mapCards = (cards: OfferingCardM2M[]) => {
    return cards?.map((cardM2M) => {
      const { cta_url, ...rest } = cardM2M.offering_cards_id;

      return {
        ...rest,
        url: cta_url,
      };
    });
  };

  const updatedInfoCardsList = useMemo(() => {
    return info_cards && mapCards(info_cards);
  }, [info_cards]);

  const updatedServiceCardsList = useMemo(() => {
    return service_cards && mapCards(service_cards);
  }, [service_cards]);

  return (
    <>
      {breadcrumbs && <BreadcrumbCustom items={breadcrumbs} />}
      {(header ||
        (updatedInfoCardsList && updatedInfoCardsList?.length > 0)) && (
        <CtaBanner
          banner={header}
          infoCards={updatedInfoCardsList}
          filesMeta={filesMeta}
        />
      )}
      {steps && steps?.length > 0 && (
        <Steps steps={steps} title={step_title} preText={step_pre_text} />
      )}

      {documents_group &&
        documents_group?.documents &&
        documents_group?.documents?.length > 0 && (
          <FormsAndDocuments
            {...documents_group}
            documentTitle={documents_title}
            preText={documents_pre_text}
            ctaText={document_cta_text}
            ctaUrl={document_cta_url}
            baseUrl={base_url}
          />
        )}

      {documents_to_submit && documents_to_submit?.length > 0 && (
        <FormsTabs
          documentList={documents_to_submit}
          title={submit_title}
          preText={submit_pre_text}
          description={submit_description}
          filesMeta={filesMeta}
        />
      )}
      {campaigns && campaigns.length > 0 && (
        <CampaignSlider
          slides={campaigns}
          fullWidth={false}
          filesMeta={filesMeta}
        />
      )}
      {contact_us_banner && (
        <HelpCard {...contact_us_banner} filesMeta={filesMeta} />
      )}
      {updatedServiceCardsList && updatedServiceCardsList.length > 0 && (
        <ServiceSection
          partnerList={updatedServiceCardsList}
          title={service_title}
          preText={service_pre_text}
          filesMeta={filesMeta}
        />
      )}
    </>
  );
};

export default GuidelinesPage;
