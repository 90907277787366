/* eslint-disable no-undef */
'use client';
import {
  GoogleMap,
  Marker,
  useLoadScript,
} from '@react-google-maps/api';
import { Button, Form, Input } from 'antd';
import cn from 'classnames';
import Image from 'next/image';
import React, { useCallback, useEffect, useState } from 'react';

import SearchIcon from '@/app/_icons/search.svg';
import { LocationCard } from '@/app/_types/components/location-card';
import { LocationsM2M } from '@/app/_types/contact-page';
import { parseCoordinate } from '@/app/_utils/text';

import Styles from './MapWrapper.module.scss';

const MapWrapper = ({ locations }: { locations: LocationsM2M[] }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: String(process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY),
  });

  const [selectedLocation, setSelectedLocation] = useState<LocationCard | null>(
    null,
  );
  const [filteredLocations, setFilteredLocations] = useState(locations);
  const [searchValue, setSearchValue] = useState('');
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

  const handleSearch = useCallback(() => {
    const filtered = locations.filter((location) => {
      return (
        location.location_card_id.city
          ?.toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        location.location_card_id.address
          ?.toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        location.location_card_id.postal_address
          ?.toLowerCase()
          .includes(searchValue.toLowerCase())
      );
    });

    setFilteredLocations(filtered);
  }, [searchValue, locations]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    handleSearch();
  };

  useEffect(() => {
    if (searchValue === '') {
      setFilteredLocations(locations);
    }
  }, [searchValue, locations]);

  useEffect(() => {
    if (mapInstance) {
      if (selectedLocation && selectedLocation.coordinates) {
        const latLng = parseCoordinate(selectedLocation.coordinates);

        if (latLng) {
          mapInstance.panTo(latLng);
          mapInstance.setZoom(15);
        }
      } else {
        mapInstance.setZoom(5);
      }
    }
  }, [selectedLocation, mapInstance]);

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <div className={Styles.mapWraperContainer}>
      <div className={Styles.searchSection}>
        <Form>
          <div className={Styles.searchBox}>
            <Input
              placeholder="Search..."
              className={cn(Styles.searchBoxInput, 'customInput')}
              value={searchValue}
              onChange={handleInputChange}
            />
            <Button
              htmlType="submit"
              className={Styles.searchBtn}
              onClick={(e) => {
                e.preventDefault();
                handleSearch();
              }}
            >
              <Image src={SearchIcon} alt="Search" height={14} width={14} />
            </Button>
          </div>
        </Form>

        <div className={Styles.locationLists}>
          {filteredLocations.map((locationM2M) => {
            const location = locationM2M.location_card_id;

            return (
              <div
                key={location.id}
                className={Styles.locationCard}
                onClick={() => setSelectedLocation(location)}
              >
                {location && (
                  <div className={Styles.locationCardHeader}>
                    <div className="headingElement">
                      <h3 className="titleMd">{location.city}</h3>
                      <h4 className="titleCaption">{location.office_type}</h4>
                    </div>
                  </div>
                )}
                <div className={Styles.locationCardBody}>
                  {location.contact_number && (
                    <a href={`tel:${location.contact_number}`}>
                      {location.contact_number}
                    </a>
                  )}
                  {location.address && <address>{location.address}</address>}
                  {location.postal_address && <p>{location.postal_address}</p>}
                  {location.business_hours &&
                    Array.isArray(location.business_hours) &&
                    location.business_hours.map((hours, i) => {
                      return <p key={i}>{hours.time}</p>;
                    })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={Styles.mapSection}>
        <GoogleMap
          zoom={10}
          center={{ lat: -26.1438, lng: 28.0248 }}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          onLoad={(mapInstance) => setMapInstance(mapInstance)}
        >
          {filteredLocations.map((locationM2M) => {
            const location = locationM2M.location_card_id;

            if (!location.coordinates) {
              return null;
            }

            const latLng = parseCoordinate(location.coordinates);

            if (latLng) {
              return (
                <Marker
                  key={location.id}
                  position={latLng}
                  visible={
                    selectedLocation
                      ? selectedLocation.id === location.id
                      : true
                  }
                />
              );
            }
            return null;
          })}
        </GoogleMap>
      </div>
    </div>
  );
};

export default MapWrapper;
