'use client';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useMemo, useState } from 'react';

import BreadcrumbCustom from '@/app/_components/breadcrumb/BreadcrumbCustom';
import { Blog } from '@/app/_types/blog';
import { Breadcrumb } from '@/app/_types/breadcrumbs';
import { Category } from '@/app/_types/categories';
import { FilesMeta } from '@/app/_types/file';
import {
  MediaPage as MediaPageType,
  MediaResourcesM2M,
} from '@/app/_types/media-page';

import Blogs from './Blogs';
import CtaBanner from './CtaBanner';
import MediaFilter from './MediaFilter';
import MediaListing from './MediaListing';
import {
  calculateResourceCounts,
  filterResourcesByCategories,
  findCategoryByValue,
} from './utils';

type Props = MediaPageType & {
  category?: string | null; // pre-selected from the query parameter
  blogs: Blog[] | null;
  filesMeta: FilesMeta;
  breadcrumbs?: Breadcrumb[] | null;
};
const MediaPage = (props: Props) => {
  const {
    header,
    blogs,
    blog_title,
    blog_pre_text,
    categories,
    resources_title,
    resources_pre_text,
    resources,
    category,
    filesMeta,
    breadcrumbs,
    view_all_navigation,
  } = props;

  const resourceDetailPermalink = props.resource_detail_navigation?.permalink;
  let resourceBaseUrl = '';

  if (resourceDetailPermalink) {
    resourceBaseUrl = resourceDetailPermalink.replaceAll('*', '');
  }

  const blogDetailPermalink = props.blog_detail_navigation?.permalink;
  let blogBaseUrl = '';

  if (blogDetailPermalink) {
    blogBaseUrl = blogDetailPermalink.replaceAll('*', '');
  }

  const allCategoriesWithResources = useMemo(() => {
    const result: Category[] = [];

    if (resources) {
      if (categories && categories.length) {
        for (const categoryM2M of categories) {
          for (const resource of resources) {
            if (
              resource.media_resources_id?.category?.value ===
              categoryM2M.categories_id.value
            ) {
              result.push(resource.media_resources_id?.category);
            }
          }
        }
      } else {
        for (const resource of resources) {
          if (resource.media_resources_id?.category) {
            result.push(resource.media_resources_id?.category);
          }
        }
      }
    }

    return result.filter(
      (item, i, allCategories) =>
        allCategories.findIndex((item2) => item2.id === item.id) === i,
    );
  }, [blogs, resourceBaseUrl]);

  const preselectedCategory = findCategoryByValue(
    allCategoriesWithResources,
    category,
  );
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    preselectedCategory,
  );
  const [filteredResources, setFilteredResources] = useState<
    MediaResourcesM2M[]
  >(resources || []);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const path = usePathname();
  const router = useRouter();

  const resourceCounts = useMemo(() => {
    // if we haven't searched by any text, count all the resources
    if (searchTerm.length === 0 && resources) {
      return calculateResourceCounts(resources);
    }
    // we've searched by text, only count the matching resources
    return calculateResourceCounts(filteredResources);
  }, [searchTerm, filteredResources]);

  const updateUrl = (category: string | null) => {
    let url = path;

    if (category) {
      url = `${url}?category=${category}`;
    }
    router.push(url, { scroll: false });
  };

  const handleSearch = (value: string, categories: string[]) => {
    const searchValue = value.toLowerCase();

    setSearchTerm(searchValue);

    if (categories.length === 0) {
      setFilteredResources([]);
      return;
    }

    // initialise to all media and blogs
    let matchingResources: MediaResourcesM2M[] = resources || [];

    // Filter categories, _ means show all
    if (categories.includes('_')) {
      setSelectedCategory(null);
      updateUrl(null);
    } else if (categories.length > 0) {
      matchingResources = filterResourcesByCategories(
        matchingResources,
        categories,
      );
      const firstSelectedCategory = findCategoryByValue(
        allCategoriesWithResources,
        categories[0],
      );

      setSelectedCategory(firstSelectedCategory || null);
      updateUrl(firstSelectedCategory?.value || null);
    }

    // filter by search term
    if (searchValue.length > 3) {
      matchingResources = matchingResources.filter((item) =>
        item.media_resources_id.title?.toLowerCase().includes(searchValue),
      );
    }

    setFilteredResources(matchingResources);
  };

  useEffect(() => {
    // If we had a preselected category, do the search on initial load
    if (preselectedCategory) {
      handleSearch('', [preselectedCategory.value]);
    }
  }, []);

  return (
    <>
      {breadcrumbs && <BreadcrumbCustom items={breadcrumbs} />}
      {header && <CtaBanner {...header} filesMeta={filesMeta} />}
      <div className="pageSection">
        <MediaFilter
          allMediaTitle={resources_title ?? 'All'}
          counts={resourceCounts}
          initialCategory={preselectedCategory}
          categories={allCategoriesWithResources}
          onSearch={handleSearch}
        />
        {filteredResources && filteredResources.length > 0 && (
          <MediaListing
            media={filteredResources}
            resourceBaseUrl={resourceBaseUrl}
            resourcesPreText={resources_pre_text}
            resourcesTitle={selectedCategory?.title ?? resources_title ?? 'All'}
            filesMeta={filesMeta}
          />
        )}
      </div>
      {blogs && blogs.length > 0 && (
        <Blogs
          media={blogs}
          blogTitle={blog_title}
          blogPreText={blog_pre_text}
          blogBaseUrl={blogBaseUrl}
          breadcrumbs={breadcrumbs}
          archivesPageNavigation={view_all_navigation?.permalink}
          filesMeta={filesMeta}
        />
      )}
    </>
  );
};

export default MediaPage;
