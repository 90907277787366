import cn from 'classnames';
import Image from 'next/image';
import React from 'react';

import { assetsUri } from '@/app/_services/api.service';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty } from '@/app/_utils/assets';

import Styles from './Brand.module.scss';

type BrandTypes = {
  content: Array<{
    brand_tab_id: {
      id: string;
      title: string;
      image: any;
      paragraph: string;
    };
  }>;
  filesMeta?: FilesMeta;
};

const Brand = (props: BrandTypes) => {
  const { content } = props;
  const filesMeta = props.filesMeta ?? {};

  return (
    <div className={Styles.brandWrapper}>
      <div className={Styles.brandList}>
        {content.map((data, index) => (
          <div key={index} className={Styles.brandItem}>
            <div className={Styles.brandContent}>
              {data.brand_tab_id.title && (
                <div className="headingElement text-primary">
                  <h4 className="titleMd">{data.brand_tab_id.title}</h4>
                </div>
              )}
              {data.brand_tab_id.paragraph && (
                <div
                  className={cn('normal', Styles.brandDescription)}
                  dangerouslySetInnerHTML={{
                    __html: data.brand_tab_id.paragraph,
                  }}
                ></div>
              )}
            </div>
            {data.brand_tab_id.image && (
              <div className={Styles.brandImageContainer}>
                <Image
                  src={`${assetsUri}/${data.brand_tab_id.image}`}
                  alt={getAltProperty(
                    filesMeta,
                    data.brand_tab_id.image ?? '',
                    'Banner',
                  )}
                  className={Styles.brandImage}
                  width={574}
                  height={410}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Brand;
