export const splitSentence = (sentence: string, splitPercentage: number) => {
  const words = sentence.split(' ');
  const totalCount = words.length;
  const splitIndex = totalCount * (splitPercentage / 100.0);

  return [
    words.slice(0, splitIndex).join(' '),
    words.slice(splitIndex).join(' '),
  ];
};

/**
 * add leading and trailing slashes to a path
 * 
 * @param path 
 */
export const sanitizeAbsolutePath = (path: string): string => {
  // take the path up to the first ? or # only
  if (path.indexOf('?') > -1) {
    path = path.substring(0, path.indexOf('?'))
  }
  if (path.indexOf('#') > -1) {
    path = path.substring(0, path.indexOf('#'))
  }

  if (!path.endsWith('/')) {
    path = path + '/';
  }
  if (!path.startsWith('/')) {
    path = '/' + path;
  }
  return path;
};

/**
 * Construct a regular expression from a path with wildcards
 * 
 * @param path 
 * @returns 
 */
export const generateRegExFromPath = (path: string): RegExp => {
  const hasWildcard = path.indexOf('*') > -1;

  if (hasWildcard) {
    path = path.replaceAll('*', '(.*)');
  }
  path = path.replaceAll('/', '\\/');
  path = `^${path}$`;
  return new RegExp(path, 'g');
};

/**
 * Parse the "POINT (x y)" string value into lat long
 * 
 * @param value
 */
export const parseCoordinate = (value: string): {lat: number, lng: number} | null => {
  const regex = new RegExp('POINT \\((?<lng>.*) (?<lat>.*)\\)', 'gm');

  const match = regex.exec(value);

  if (match && match.groups) {
    return {lat: parseFloat(match.groups['lat']), lng: parseFloat(match.groups['lng'])}
  }
  return null
};

export const extractCityFromPlaceAddress = (address: string): string | null => {
  const addressParts = address.split(',');

  if (addressParts.length > 1) {
    // return the second last part: "Some Place, Pretoria, South Africa"
    // being the city or town
    return addressParts[addressParts.length - 2];
  }
  return null;
};
