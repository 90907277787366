'use client';

import { Button, Input } from 'antd';
import cn from 'classnames';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';

import CheckBoxCustom from '@/app/_components/checkbox/CheckBoxCustom';
import FilterIcon from '@/app/_icons/filter.svg';
import SearchIcon from '@/app/_icons/search.svg';
import { Category } from '@/app/_types/categories';

import Styles from './MediaListing.module.scss';

type Props = {
  allMediaTitle: string;
  categories: Category[];
  initialCategory?: Category | null;
  counts: Record<string, number>;
  onSearch: (value: string, categories: string[]) => void;
};

const MediaFilter = (props: Props) => {
  const { allMediaTitle, categories, initialCategory, counts, onSearch } =
    props;

  const initialSelectedCategories = initialCategory
    ? [initialCategory.value]
    : ['_'];
  const initialSelectedCategory = initialCategory ? initialCategory.value : '_';

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    initialSelectedCategories,
  );
  const [selectedCategory, setSelectedCategory] = useState<string>(
    initialSelectedCategory,
  );

  const filterMenuRef = useRef<HTMLDivElement>(null);
  const filterBtnRef = useRef<HTMLAnchorElement>(null);
  const activeFilterRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const offset = 120;

    if (
      selectedCategory &&
      selectedCategory !== '_' &&
      activeFilterRef.current
    ) {
      const scrollPosition = activeFilterRef.current.offsetTop - offset;

      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }, [selectedCategory]);

  const toggleFilter = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsFilterOpen((prev) => !prev);
  };

  const onFilterItemChange = (itemName: string, checked: boolean) => {
    setSelectedCategories((prev) => {
      if (checked) {
        return [...prev, itemName].filter((item) => item != '_');
      }
      const newState = prev.filter((item) => item !== itemName);

      return newState.length === 0 ? ['_'] : newState;
    });
  };

  const onFilterButtonClick = (category: string) => {
    setSelectedCategory(category);
    setSelectedCategories([category]);
  };

  useEffect(() => {
    onSearch(searchTerm, selectedCategories);
  }, [searchTerm, selectedCategories]);

  return (
    <div className="container">
      <div className={Styles.mediaListingWrapper}>
        <div className={Styles.searchFilterContainer}>
          <div className={Styles.searchWrapper}>
            <div className={Styles.searchContainer}>
              <div className={Styles.searchBox}>
                <Input
                  placeholder="Search..."
                  className={Styles.searchBoxInput}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Button className={Styles.searchBtn}>
                  <Image src={SearchIcon} alt="Search" height={14} width={14} />
                </Button>
              </div>
              <a
                onClick={toggleFilter}
                className={Styles.filterBtn}
                ref={filterBtnRef}
              >
                <span className={Styles.btnText}>Filter</span>
                <Image
                  src={FilterIcon}
                  alt="Filter"
                  className={Styles.btnIcon}
                  height={14}
                  width={14}
                />
              </a>
            </div>

            <div
              ref={filterMenuRef}
              className={cn(Styles.filterMenu, isFilterOpen && Styles.open)}
            >
              {categories.map((category: Category, index: number) => (
                <div key={index} className={Styles.filterMenuItem}>
                  <div className="customInput mb-0">
                    <CheckBoxCustom
                      name={category.value}
                      label={`${category.title} (${counts[category.value] || 0})`}
                      onChange={(e) =>
                        onFilterItemChange(category.value, e.target.checked)
                      }
                      checked={selectedCategories.includes(category.value)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={Styles.filterBtnList} ref={activeFilterRef}>
          <div
            className={cn(Styles.filterBtnBig, {
              [Styles.active]: selectedCategory === '_',
            })}
            onClick={() => onFilterButtonClick('_')}
          >
            <div className="headingElement">
              <h5 className="titleSm">{allMediaTitle}</h5>
            </div>
            <div>{counts['_'] || 0} Resources</div>
          </div>

          {categories.map((category: Category, index: number) => (
            <div
              key={index}
              className={cn(Styles.filterBtnBig, {
                [Styles.active]: selectedCategory === category.value,
              })}
              onClick={() => onFilterButtonClick(category.value)}
              id={category.value}
            >
              <div className="headingElement">
                <h5 className="titleSm">{category.title}</h5>
              </div>
              <div>{counts[category.value] || 0} Resources</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MediaFilter;
