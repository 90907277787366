import cn from 'classnames';
import Image from 'next/image';
import React from 'react';

import { assetsUri } from '@/app/_services/api.service';

import Styles from './Leadership.module.scss';

type LeadershipTypes = {
  content: Array<{
    leadership_tab_id: {
      id: string;
      group_name: string;
      members: Array<{
        members_id: {
          id: string;
          image: any;
          linkedin_url: string;
          name: string;
          title: string;
        };
      }>;
    };
  }>;
};
const Leadership = (props: LeadershipTypes) => {
  const { content } = props;

  return (
    <div className={Styles.leadershipWrapper}>
      <div className={Styles.leadershipList}>
        {content.map((data, index) => (
          <div key={index} className={Styles.leadershipItem}>
            {data.leadership_tab_id.group_name && (
              <div className="headingElement text-primary">
                <h4 className="titleMd">{data.leadership_tab_id.group_name}</h4>
              </div>
            )}
            {data.leadership_tab_id.members && (
              <div className={Styles.membersList}>
                {data.leadership_tab_id.members.map((member, index) => (
                  <a
                    href={member.members_id.linkedin_url}
                    target="_blank"
                    key={index}
                    className={Styles.memberCard}
                  >
                    <Image
                      src={`${assetsUri}/${member.members_id.image}`}
                      alt={member.members_id.name}
                      height={70}
                      width={70}
                      className={Styles.memberImage}
                    />

                    <div className={cn('headingElement', Styles.memberDetails)}>
                      <h4 className="titleSm light">
                        {member.members_id.name}
                      </h4>
                      <p className="body-sm text-primary ">
                        {member.members_id.title}
                      </p>
                    </div>
                  </a>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Leadership;
