'use client';
import cn from 'classnames';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import { CTABanner } from '@/app/_types/components/cta-banner';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './Banner.module.scss';

type BannerProps = CTABanner & {
  filesMeta: FilesMeta;
};

const Banner = (props: BannerProps) => {
  const { title, title_2, paragraph, image, filesMeta } = props;

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;

      if (scroll >= 175) {
        setScrollPosition(scroll - 175);
      } else {
        setScrollPosition(0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const zoomStyle = {
    transform: `scale(${1 + scrollPosition / 4000})`,
  };

  return (
    <div className={Styles.bannerWrapper}>
      <div className="container">
        <div className={Styles.bannerContent}>
          <div className="headingElement">
            {(title || title_2) && (
              <h1
                className={cn(
                  'titleXXl text-primary strong',
                  Styles.ctaBannerTitle,
                )}
              >
                {title_2 ? (
                  <>
                    <span className="light">{title}</span> {title_2}
                  </>
                ) : (
                  title
                )}
              </h1>
            )}
          </div>

          {paragraph && (
            <div
              className={Styles.description}
              dangerouslySetInnerHTML={{
                __html: paragraph,
              }}
            ></div>
          )}
        </div>
      </div>
      <div className={Styles.bannerImageContainer}>
        <div className={Styles.bannerImageWrapper} style={zoomStyle}>
          <Image
            src={getDirectusAssetURI(image ?? '') || ''}
            alt={getAltProperty(filesMeta, image ?? '', 'Banner image')}
            fill
            className={Styles.bannerImage}
            priority
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
