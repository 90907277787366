import Link from 'next/link';
import React from 'react';
import slugify from 'react-slugify';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { DocumentGroup } from '@/app/_types/components/document-group';

import Styles from './FormsAndDocuments.module.scss';

type FormsAndDocumentsProps = DocumentGroup & {
  documentTitle?: string | null;
  preText?: string | null;
  ctaText?: string | null;
  ctaUrl?: string | null;
  baseUrl?: string | null;
};

const FormsAndDocuments = (props: FormsAndDocumentsProps) => {
  const { documentTitle, preText, documents, ctaText, ctaUrl, baseUrl } = props;

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.formsAndDocumentsWrapper}>
          <div className={Styles.sectionHeadingContainer}>
            <div className={Styles.sectionLeftContent}>
              <div className="headingElement">
                {preText && <h3 className="subTitle">{preText}</h3>}
                {documentTitle && <h2 className="titleXl">{documentTitle}</h2>}
              </div>
            </div>
          </div>
          <div className={Styles.documentsContainer}>
            {documents && (
              <>
                <div className={Styles.documentsList}>
                  {documents.map(({ documents_component_id }, index) => (
                    <Link
                      href={`${baseUrl}?documentName=${slugify(documents_component_id?.title)}`}
                      key={index}
                      className={Styles.documentItem}
                    >
                      <div className="headingElement">
                        <h3 className="titleMd">
                          {documents_component_id?.title}
                        </h3>
                      </div>
                      <div className={Styles.documentParagraph}>
                        {documents_component_id?.description}
                      </div>
                    </Link>
                  ))}
                </div>
                {ctaText && ctaUrl && (
                  <ButtonCustom href={ctaUrl} btnText={ctaText} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormsAndDocuments;
