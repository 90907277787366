import cn from 'classnames';
import React from 'react';

import { Product } from '@/app/_types/components/product';

import ButtonCustom from '../button/ButtonCustom';

import styles from './OfferCard.module.scss';

interface ProductProps extends Product {
  productIndex?: string;
}

const OfferCard = (props: ProductProps) => {
  const {
    title,
    learn_more_cta_url,
    learn_more_cta_text,
    paragraph,
    productIndex,
  } = props;

  return (
    <div className={cn('headingElement', styles.offerCard)}>
      <div className={styles.offerCardBody}>
        <div className="mediumSubTitle normal">
          <p>{productIndex}</p>
        </div>
        <div className={styles.textWrapper}>
          <h2 className="titleMd">{title}</h2>
          {paragraph && (
            <div
              className={cn(
                'smallSubTitle normal editorDescription',
                styles.description,
              )}
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          )}
        </div>
        {learn_more_cta_url && (
          <ButtonCustom
            btnText={
              learn_more_cta_text ? learn_more_cta_text : 'Find out more'
            }
            href={learn_more_cta_url}
            fitWidth={true}
            moduleClass="offerCardBtn"
          />
        )}
      </div>
    </div>
  );
};

export default OfferCard;
