import cn from 'classnames';
import Image from 'next/image';

import FormSubmissionIcon from '@/app/_icons/form-submission.png';
import { getDirectusAssetURI } from '@/app/_utils/assets';

import styles from './FormSuccessMessage.module.scss';

interface Props {
  submittedImage?: string | null;
  submittedParagraph?: string | null;
  submittedTitle?: string | null;
}

const FormSuccessMessage = ({
  submittedImage,
  submittedParagraph,
  submittedTitle,
}: Props) => {
  return (
    <div>
      <div className={cn('headingElement', styles.formSubmit)}>
        <div className={styles.submitContent}>
          <div className={styles.submitLogo}>
            {submittedImage && (
              <Image
                src={getDirectusAssetURI(submittedImage) || FormSubmissionIcon}
                alt="form-success-icon"
                height={160}
                width={160}
              />
            )}
          </div>
          <h4 className={cn('titleLg', styles.submitHeading)}>
            {submittedTitle}
          </h4>
          {submittedParagraph && (
            <div
              className={styles.submitMessage}
              dangerouslySetInnerHTML={{
                __html: submittedParagraph,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FormSuccessMessage;
