'use client';

import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import { CopyComponent } from '@/app/_types/components/copy-component';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './CtaBanner.module.scss';

type Props = CopyComponent & {
  image2?: string | null; // the large image after the copy
  filesMeta: FilesMeta;
};

const CtaBanner = (props: Props) => {
  const { pre_text, title, paragraph, image2, contact, filesMeta } = props;

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;

      if (scroll >= 250) {
        setScrollPosition(scroll - 250);
      } else {
        setScrollPosition(0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const zoomStyle = {
    transform: `scale(${1 + scrollPosition / 4000})`,
  };

  return (
    <div className={Styles.ctaBannerWrapper}>
      <div className="container">
        <div className={Styles.ctaBannerContent}>
          {title && (
            <div className="headingElement">
              {pre_text && <h3 className="subTitle">{pre_text}</h3>}
              {title && <h2 className="titleXl">{title}</h2>}
            </div>
          )}

          <div className={Styles.sectionRightContent}>
            {paragraph && (
              <div className={Styles.description}>
                <div dangerouslySetInnerHTML={{ __html: paragraph }}></div>
              </div>
            )}
            {contact && (
              <div className={Styles.contactList}>
                {contact.map((contactItem, index) => {
                  const contactDetail = contactItem.contact_component_id;

                  if (!contactDetail.numbers?.length) {
                    return null;
                  }

                  return (
                    <a
                      key={index}
                      href={`tel:${contactDetail.numbers[0].number}`}
                      className={Styles.contactBox}
                    >
                      <div className="headingElement">
                        {contactDetail.numbers_title && (
                          <h4 className="titleCaption">
                            {contactDetail.numbers_title}
                          </h4>
                        )}
                        {contactDetail.numbers[0].number && (
                          <h3 className={Styles.contactNumber}>
                            {contactDetail.numbers[0].number}
                          </h3>
                        )}
                        {contactDetail.numbers_note && (
                          <p className="text-neutral08 mt-2 mb-0">
                            {contactDetail.numbers_note}
                          </p>
                        )}
                      </div>
                    </a>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={Styles.bannerImageContainer}>
        <div className={Styles.bannerImageWrapper} style={zoomStyle}>
          {image2 && (
            <Image
              src={getDirectusAssetURI(image2) || ''}
              alt={getAltProperty(filesMeta, image2 ?? '', 'Banner')}
              width={1920}
              height={640}
              className={Styles.bannerImage}
              priority
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CtaBanner;
