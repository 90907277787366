'use client';

import { Divider } from 'antd';
import Image from 'next/image';
import React, { useRef, useState } from 'react';

import { PartnerM2M } from '@/app/_types/components/partner';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './PartnershipTabs.module.scss';
import UmaPageContent from './UmaPageContent';

type PartnershipTabsTypes = {
  partners: PartnerM2M[];
  title?: string | null;
  preText?: string | null;
  filesMeta: FilesMeta;
};

const PartnershipTabs = (props: PartnershipTabsTypes) => {
  const { title, preText, partners, filesMeta } = props;

  const [activeTab, setActiveTab] = useState<number | null>(null);

  const tabContentRef = useRef<HTMLDivElement | null>(null);

  const scrollToContent = () => {
    if (tabContentRef.current) {
      const topOffset =
        tabContentRef.current.getBoundingClientRect().top +
        window.scrollY -
        120;

      window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
  };

  const handleTabClick = (index: number) => {
    setActiveTab(activeTab === index ? null : index);

    setTimeout(() => scrollToContent(), 100);
  };

  const groupedPartners = [];

  if (partners?.length) {
    for (let i = 0; i < partners.length; i += 3) {
      groupedPartners.push(partners.slice(i, i + 3));
    }
  }

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.partnershipWrapper}>
          <div className={Styles.partnershipContentContainer}>
            <div className={Styles.partnershipContentHeadingSection}>
              {title && (
                <div className="headingElement">
                  {preText && <h3 className="subTitle">{preText}</h3>}
                  {title && <h2 className="titleXl">{title}</h2>}
                </div>
              )}
            </div>
          </div>

          <div className={Styles.partnershipTabsContainer}>
            {groupedPartners.map((group, groupIndex) => (
              <React.Fragment key={groupIndex}>
                <div className={Styles.partnershipTabsCardsList}>
                  {group.map(({ partners_component_id }, index) => {
                    const globalIndex = groupIndex * 3 + index;

                    return (
                      <div
                        key={globalIndex}
                        className={`${Styles.partnershipTabsCard} ${activeTab === globalIndex ? Styles.active : ''}`}
                        onClick={() => handleTabClick(globalIndex)}
                      >
                        <div className={Styles.partnershipTabsCardImage}>
                          <Image
                            src={
                              getDirectusAssetURI(
                                partners_component_id?.logo ?? '',
                              ) || ''
                            }
                            alt={getAltProperty(
                              filesMeta,
                              partners_component_id?.logo ?? '',
                              'product logo',
                            )}
                            width={468}
                            height={188}
                            quality={100}
                          />
                        </div>
                        <div className={Styles.partnershipTabsCardBody}>
                          <div className="headingElement">
                            <h3 className="titleSm">
                              {partners_component_id?.title}
                            </h3>
                          </div>
                          <div
                            className={Styles.description}
                            dangerouslySetInnerHTML={{
                              __html: partners_component_id?.paragraph,
                            }}
                          ></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {activeTab !== null &&
                  Math.floor(activeTab / 3) === groupIndex && (
                    <div
                      ref={tabContentRef}
                      className={Styles.tabContentContainer}
                    >
                      {partners[activeTab]?.partners_component_id?.details?.map(
                        ({ copy_component_id }, idx) => (
                          <div
                            className={Styles.partnershipTabContent}
                            key={idx}
                          >
                            <UmaPageContent
                              {...copy_component_id}
                              filesMeta={filesMeta}
                            />
                          </div>
                        ),
                      )}
                    </div>
                  )}
                {groupIndex < groupedPartners.length - 1 && (
                  <Divider className={Styles.quickLinkDivider} />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnershipTabs;
