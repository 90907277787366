'use client';

import cn from 'classnames';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import React, { useEffect, useRef } from 'react';
import slugify from 'react-slugify';

import { CopyComponent } from '@/app/_types/components/copy-component';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './PageContent.module.scss';

type Props = CopyComponent & {
  filesMeta: FilesMeta;
};

const PageContent = (props: Props) => {
  const { filesMeta, image, paragraph, pre_text, title } = props;

  const pathName = usePathname();
  const ourBrandRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const offset = 120;
    const hash = window.location.hash;
    const activeSection = hash.replace('#', '');

    if (activeSection === slugify(title) && ourBrandRef.current) {
      const scrollPosition = ourBrandRef.current.offsetTop - offset;

      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }, [pathName]);

  return (
    <div
      className={cn('pageSection', Styles.pageSection)}
      id={slugify(title)}
      ref={ourBrandRef}
    >
      <div className="container">
        <div className={Styles.pageContentWrapper}>
          <div
            className={cn(
              Styles.pageContentContainer,
              image && Styles.pageContentImageContainer,
            )}
          >
            <div className={Styles.pageContentHeadingSection}>
              {image ? (
                <Image
                  src={getDirectusAssetURI(image) || ''}
                  alt={getAltProperty(filesMeta, image ?? '', 'Banner')}
                  className={Styles.bannerImage}
                  height={518}
                  width={574}
                />
              ) : (
                pre_text && (
                  <div className="headingElement">
                    {pre_text && <h3 className="subTitle ">{pre_text}</h3>}
                    {title && <h2 className="titleXl">{title}</h2>}
                  </div>
                )
              )}
            </div>
            {pre_text && (
              <div className={Styles.pageContentSection}>
                {image ? (
                  <div className="headingElement">
                    {pre_text && <h3 className="subTitle ">{pre_text}</h3>}
                    {title && <h2 className="titleXl">{title}</h2>}
                  </div>
                ) : null}

                <div
                  className={cn('normal', Styles.pageContentDetails)}
                  dangerouslySetInnerHTML={{
                    __html: paragraph,
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContent;
